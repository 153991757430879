.l-main {
  @include margin-root;
  padding: 0 20px;

  > :last-child {
    margin-bottom: 100px;
  }

  // 包含要素の上下マージンを貫通させない
  &:before,
  &:after {
    content: '';
    display: table;
  }

  > *,
  &__inner > * {
    max-width: $base-width;
    margin-left: auto;
    margin-right: auto;
    html.layout-narrow & {
      max-width: $narrow-width;
    }
  }

  &__inner {
    max-width: none;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    &:before,
    &:after {
      content: '';
      display: table;
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    // 汎用
    &--bg-stripe {
      background: url(../img/main_bg_01.png);
    }
  }
}
