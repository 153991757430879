.c-colbox {
  @include margin-root;
  padding: 30px 40px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  font-size: 14px;
  @include sp {
    padding-left: 20px;
    padding-right: 20px;
  }

  .c-hd-3 + &,
  .c-hd-4 + &,
  .c-hd-5 + & {
    margin-top: -8px;
  }

  // 黄色地
  &--yellow {
    background: #fcfbef;
    border-color: #fcfbef;
  }

  // エラー
  &--err {
    background: #ffe0e5;
    border-color: #ffe0e5;
  }

  // ストライプボーダー
  &--stripe-bordered {
    position: relative;
    border: 10px solid transparent;
    background-clip: padding-box;

    &:after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      background: #fff url(../img/stripe_bg_01.png);
      z-index: -1;
    }
  }

  // 罫線のみ
  &--nobg {
    background: transparent;
    border-color: #e6e6e6;
  }

  // One Point
  &--onepoint {
    position: relative;
    padding-left: 185px;
    @include sp {
      padding-top: calc(2em + 80px);
      padding-left: 20px;
    }

    &:before {
      content: 'One Point';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 115px;
      height: calc(2em + 38px);
      margin: auto;
      padding-top: 38px;
      color: $color-red;
      background: url(../img/icon_onepoint.png) no-repeat 50% 0;
      font-size: 24px;
      font-weight: 200; // Light
      box-sizing: border-box;
      letter-spacing: normal;
      @include sp {
        top: 20px;
        bottom: auto;
        right: 0;
        left: 0;
      }
    }
  }

  // 全幅
  &--full {
    &, html.layout-narrow & {
      max-width: none;
      margin-left: -20px;
      margin-right: -20px;
    }

    > * {
      max-width: $base-width;
      margin-left: auto;
      margin-right: auto;
      html.layout-narrow & {
        max-width: $narrow-width;
      }
    }
  }
}
