.p-recipe-theme {
  margin-top: 80px;
  margin-bottom: 80px;
  @include sp {
    margin: 0 -20px 30px;
  }

  &__header {
    display: flex;
    @include sp {
      display: block;
    }

    &__pct {
      flex: 1 1 auto;
      width: 376px; // IE11
      @include sp {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 auto;
      width: 664px; // IE11
      padding: 0 25px;
      color: #572513;
      background: #efefef url(../img/recipe_theme_bg.png);
      border-left: 5px solid #999;
      box-sizing: border-box;
      line-height: 1.4;
      @include sp {
        width: 100%;
        border-left: none;
        border-top-width: 5px;
        border-top-style: solid;
        padding: 20px;
      }

      .__title {
        @include sp {
          text-align: center;
        }
      }

      .__sub {
        font-size: 22px;
        font-weight: 300;
        @include sp { font-size: 16px; }
      }

      .__main {
        font-size: 40px;
        font-weight: 200;
        @include sp { font-size: 30px; }

        span { color: gray; }
      }

      .__lead {
        margin-top: 12px;
        @include sp { font-size: 13px; }
      }
    }
  }

  &__page-title {
    margin-top: 30px;
    text-align: center;

    > strong {
      margin-right: 1em;
      font-weight: inherit;
      color: $color-red;
    }
  }

  // 粉こねレシピ
  &--01 &__header__text {
    background-color: #f7ebd3;
    border-color: #ee9d00;
    .__main span { color: #ee9d00; }
  }

  // 野菜たっぷりレシピ
  &--02 &__header__text {
    background-color: #e3f1da;
    border-color: #92c273;
    .__main span { color: #5ea52f; }
  }

  // アレンジレシピ
  &--03 &__header__text {
    background-color: #fbe3e3;
    border-color: #f22f2f;
    .__main span { color: #f22f2f; }
  }

  // 時短エコレシピ
  &--04 &__header__text {
    background-color: #e1f1fb;
    border-color: #9bc3f4;
    .__main span { color: #60a2e0; }
  }

  // 絶品スイーツレシピ
  &--05 &__header__text {
    background-color: #fce6e8;
    border-color: #ec739e;
    .__main span { color: #ec739e; }
  }

  // 青春コナレシピ
  &--06 &__header__text {
    background-color: #dbefe7;
    border-color: #40b1b5;
    .__main span { color: #40b1b5; }
  }

  // 魚介レシピ
  &--07 &__header__text {
    background-color: #d9e5f4;
    border-color: #4a66b9;
    .__main span { color: #4a66b9; }
  }

  // ミートソースレシピ
  &--meat-source &__header__text {
    background-color: #faf7df;
    background-image: none;
    border: none;
    .__main span { color: #d43d01; }
  }
}
