.p-faq-search {
  padding: 28px;
  max-width: 800px;
  background: $color-beige;
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
    padding: 25px 20px;
  }

  &__tab {
    display: none;
    color: #000;
    font-weight: 500; // medium
    margin-bottom: 20px;

    @include sp {
      display: flex;
      justify-content: space-between;
      
      li {
        flex: 0 1 auto;

        &.__sep {
          &:before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 1.5em;
            background: rgba(black, .15);
            vertical-align: middle;
          }
        }
      }

      a {
        display: block;
        text-decoration: none;
        border-bottom: 2px solid transparent;

        &:hover {
          text-decoration: none;
        }

        &.is-current {
          border-bottom-color: $color-red;
        }
      }
    }
  }

  &__product,
  &__purpose {
    @include sp {
      display: none;
      &.is-current {
        display: block;
      }
    }
  }

  &__product__title,
  &__purpose__title {
    color: #000;
    margin-bottom: 7px;
    @include sp {
      display: none;
    }
  }

  &__content {
    display: flex;
    @include sp {
      display: block;
    }
  }

  &__product {
    flex: 1 0 500px;

    &__list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      letter-spacing: normal;
      line-height: 1.35;
      background: #fff;
      @include sp {
        display: block;
        border: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #e5e5e5;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }

      li {
        flex: 0 0 50%;
        height: 66px;
      }

      a {
        display: flex;
        align-items: center;
        height: 100%;
        text-decoration: none;
        border-right: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        @include sp { border-right: none; }

        &:hover {
          text-decoration: none;
        }
      }

      .__image {
        width: 90px;

        img {
          display: block;
          margin: auto;
        }
      }

      .__text {
        padding-right: 10px;
      }
    }
  }

  &__purpose {
    margin-left: 28px;
    @include sp {
      margin-left: 0;
    }

    &__list {
      font-size: 14px;
      letter-spacing: normal;
      line-height: 1.35;
      background: #fff;
      border-right: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;

      li {
        border-top: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
      }

      a {
        padding: 12px 15px;
        display: block;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__product__list,
  &__purpose__list {
    & + * {
      margin-top: 15px;
    }
  }
}
