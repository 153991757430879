.p-sitemap {
  column-count: 3;
  column-gap: 40px;
  @include sp {
    column-count: 1;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ul {
    padding-left: 2em;
  }
  > li {
    margin-bottom: 2em;
    break-inside: avoid;
    > a {
      display: block;
      @extend .c-hd-2;
      &:hover { text-decoration: none; }
    }
    &:first-child > a {
      margin-top: 0;
    }
    > ul {
      list-style: outside disc;
      > li {
        ul {
          margin-bottom: 1em;
          font-size: 13px;
          color: #555;
          li {
            list-style: outside square;
          }
        }
      }
    }
  }
}
