.p-product-top-section {
  font-size: 14px;
  line-height: 1.9;

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
    }

    li {
      flex: 0 0 auto;
      padding: 10px;
      width: calc((100% - 40px) / 2);
      background: url(../img/stripe_bg_02.png);
      @include sp {
        width: 100%;
        margin-bottom: 20px;
        &:last-child { margin-bottom: 0; }
      }

      a {
        display: block;
        padding: 25px 35px;
        height: 100%;
        color: inherit;
        background: #fff;
        text-decoration: none;
      }

      .__name {
        color: $color-red;
        font-size: 18px;
        text-align: center;
      }

      .__detail {
        display: flex;
        margin-top: 15px;
        @include sp {
          flex-direction: column;
        }
      }

      .__image {
        min-width: 180px;
        margin-top: 8px;
        text-align: center;
        img {
          display: block;
          margin: auto;
        }
        figcaption {
          display: block;
          margin-top: 6px;
          > * + * {
            margin-left: .5em;
          }
        }
      }

      .__text {
        margin-left: 15px;
      }
    }
  }
}

