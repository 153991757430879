.c-index-list {
  $margin-top: 30px;
  margin-bottom: 60px;

  &__items {
    @include clearfix;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    a {
      color: inherit;
      text-decoration: none;
      &:hover { text-decoration: none; }
    }

    > * {
      position: relative;
      width: 100%;
      //background: rgba(lime, .1);

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -20px;
        right: -20px;
        left: -20px;
        bottom: 0;
        background: #fff;
        transform: scale(.96);
        box-shadow: 0 2px 10px rgba(black, .25);
        opacity: 0;
        transition: all 240ms ease;
        pointer-events: none;
        @include sp { content: none; }
      }

      &.is-hover:before {
        transform: scale(1);
        opacity: 1;
      }

      @include sp {
        //margin: 0 0 25px;
        padding: 20px 0;
        border-bottom: 1px solid #e5e5e5;
        &:last-child { margin-bottom: 0; }

        &.u-nolastborder {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      > a {
        @include clearfix;
        @include sp {
          > :last-child { margin-bottom: 0; }
        }
      }
    } // > *

    .__image {
      position: relative;
      margin-bottom: 20px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: auto;
        transition: transform 2s;
      }
    }
    .is-hover .__image img {
      transform: scale(1.05);
    }

    .__text {
      position: relative;
      font-size: 14px;
      line-height: (26/14);
      @include sp {
        > :last-child { margin-bottom: 0; }
      }
    }

    .__title {
      position: relative;
      display: block;
      width: 100%;
      color: $color-red;
      font-size: 18px;
      line-height: (30/18);
      letter-spacing: .1em;
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      > span { display: inline-block; }
      @include sp {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    a.__title,
    a .__title {
      padding-right: 20px;
      &:after {
        @include icon($char-arrow-s);
        position: absolute;
        //top: 50%;
        right: -3px;
        //margin-top: -5px;
        top: 10px;
        color: $color-red;
        font-size: 11px;
      }
    }

    .is-hover .__title:last-child {
      border-bottom-color: transparent;
    }

    .__list,
    .__lists {
      position: relative;
      font-size: 14px;
      line-height: (23/14);
      list-style: none;
      margin-bottom: 25px;
      @include sp {
        margin: 20px 0 -20px;
      }

      > li,
      > ul > li {
        > * {
          display: block;
          margin-top: -1px;
          padding: 12px 5px;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          transition: background 120ms ease;
          &:hover { background: rgba(black, .03); }
          @include sp {
            display: flex;
            margin-top: 0;
            padding: 12px 0;
            height: 100%;
            border-bottom: none;
          }
        }
      }
    }

    .__lists {
      display: flex;
      @include sp {
        flex-direction: column;
      }

      > * {
        margin-left: 30px;
        &:first-child { margin-left: 0; }
        @include sp {
          margin-left: 0;
        }
      }

      ul {
        flex: 1 0 50px;
        @include sp {
          flex: 1 0 auto;
        }

        &.__img {
          flex: 0 1 auto;
          text-align: center;

          > * { // li
            margin-top: -1px;
            @include sp {
              display: block;
              width: 100%;
              text-align: center;
              padding: 20px 0;
              border-top: 1px solid #e5e5e5;
              &:last-child { padding-bottom: 0; }
            }

            > * { // a
              display: inline-block;
              border: none;
              padding: 0;

              &:hover {
                background: none;
                text-decoration: underline;
              }
            }
          }

          img {
            vertical-align: bottom;
            @include sp {
              min-width: 130px;
            }
          }
        }
      }
    } // .__lists


    .__lead {
      position: relative;
      margin-top: 15px;
      margin-bottom: 20px;
      a {
        text-decoration: underline;
        &:hover { color: $color-red; }
      }
    }

    .__body {
      position: relative;
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }

  &--col-1 &__items > * {
    &:nth-child(n+2) { margin-top: 30px; }
    @include sp {
      &:nth-child(n+2) { margin-top: 0; }
    }

    .__image {
      float: left;
      margin-right: 40px;
      margin-bottom: 20px;
      width: calc((100% - 40px * 3) / 4);
      @include sp {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }

    .__text {
      overflow: hidden;
    }

    .__title {
      display: inline-block;
      width: auto;
      font-size: 18px;
      line-height: 1.666;
      letter-spacing: .07em;
      padding-right: 0;
      padding-bottom: 0;
      border-bottom: none;

      &:after {
        position: relative;
        top: -2px;
        right: auto;
        display: inline-block;
        margin-left: 10px;
        font-weight: 500; // medium
      }

      + .__list,
      + .__lists {
        margin-top: 15px;
      }
    }
  } // &--col-1

  &--col-2 &__items > * {
    margin-left: 40px;
    width: calc((100% - 40px * 1) / 2);
    &:nth-child(n+3) { margin-top: $margin-top; }
    &:nth-child(2n+1) { margin-left: 0; }
    @include sp {
      width: 100%;
      margin-left: 0;
      &:nth-child(n+3) { margin-top: 0; }
    }

    .__image {
      float: left;
      width: calc((100% - 40px) / 2);
      margin-right: 20px;
      @include sp {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }

    .__text {
      overflow: hidden;
    }

    .__title {
      padding-bottom: 0;
      border-bottom: none;
      font-size: 20px;
      &:after { top: 12px; }
    }
  } // &--col-2

  &--col-3 &__items > * {
    margin-left: 40px;
    width: calc((99.9% - 40px * 2) / 3);
    &:nth-child(n+4) { margin-top: $margin-top; }
    &:nth-child(3n+1) { margin-left: 0; }
    @include sp {
      width: 100%;
      margin-left: 0;
      &:nth-child(n+4) { margin-top: 0; }
    }
  } // &--col-3

  &--col-4 &__items > * {
    margin-left: 40px;
    width: calc((100% - 40px * 3) / 4);
    &:nth-child(n+5) { margin-top: $margin-top; }
    &:nth-child(4n+1) { margin-left: 0; }
    @include sp {
      width: 100%;
      margin-left: 0;
      &:nth-child(n+5) { margin-top: 0; }
    }
  } // &--col-4
}
