@mixin sp {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin dpr2 {
  @media 
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) { 
    @content;
  }
}

@mixin tb {
  @media (max-width: $breakpoint_tb) {
    @content;
  }
}

@mixin en {
  [lang="en"] & {
    @content;
  }
}

@mixin inline-block-list ($letter-spacing:normal, $display:inline-block) {
  letter-spacing: -.4em;
  > * {
    display: $display;
    letter-spacing: $letter-spacing;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin placeholder {
  &:placeholder-shown { @content }
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder { @content }
  &::-moz-placeholder { @content }
  &:-ms-input-placeholder { @content }
}

@mixin input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  padding: 0;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  line-height: inherit;
  letter-spacing: inherit;
  box-sizing: inherit;
  border-radius: 0;
  //-webkit-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
}

@mixin icon ($char:'') {
  content: $char;
  font-family: 'nisshin' !important;
  speak: none;
  font-style: normal;
  font-weight: normal; // icomoon
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin margin-root () {
  > * {
    margin-bottom: 20px;
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }
}

%button-chrome {
  @include input-reset;
  position: relative;
  display: inline-flex;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  color: $color-black;
  background: #fff;
  border: 1px solid #f7b2bc;
  border-radius: 21px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  transition: all 100ms ease;

  i {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -.5em;
  }
}

%button-chrome-hover {
  color: #fff;
  background: $color-red;
  border-color: $color-red;
  &:before { color: inherit; }
  //i { color: inherit; }
}

