.p-csr-download-list {
  margin-bottom: 80px;
  max-width: 500px;
  padding: 10px;
  background: url(../img/stripe_bg_01.png);

  &__inner {
    @include margin-root;
    padding: 35px 40px;
    background: #fff;
    margin-left: 10px;
    &:first-child { margin-left: 0; }
    @include sp {
      margin-left: 0;
      margin-top: 10px;
      &:first-child { margin-top: 0; }
      padding: 20px;
    }
  }

  .c-index-list__items {
    > :before { content: none; }
    .__image { width: auto; }
  }
}
