.p-research-top-section {
  display: flex;
  margin-bottom: 80px;
  align-items: center;
  @include sp {
    margin-top: 80px;
    flex-direction: column;
  }

  &__pct {
    flex: 0 0 50%;
    text-align: right;
    @include sp {
      margin-bottom: 20px;
    }

    img { max-width: 100%; }
  }

  &__txt {
    flex: 0 0 50%;
    padding: 0 50px;
    font-size: 16px;
    @include sp {
      padding: 0;
    }

    > * {
      margin-bottom: 1.5em;
      &:last-child { margin-bottom: 0; }
    }
  }
}
