.p-school-header {
  display: flex;
  margin-top: 80px;
  margin-bottom: 70px;
  background: #faf7df;
  @include sp {
    margin: 0 -20px 40px;
    flex-direction: column;
  }

  &__pct {
    flex: 0 0 auto;
    width: (320/800)*100%;
    @include sp {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    flex: 1 1 auto;
    width: (480/800)*100%;
    padding: 0 30px;
    line-height: (24/14);
    box-sizing: border-box;
    @include sp {
      display: block;
      padding: 30px 20px;
      width: 100%;
    }
  }

  .__title {
    font-size: 30px;
    font-weight: 200; // light
    color: $color-red;
    letter-spacing: .04em;
    @include sp {
      font-size: 24px;
      text-align: center;
    }
  }

  .__lead {
    margin-top: 15px;
  }
}
