.c-catch {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 500; // medium
  text-align: center;
  font-size: 20px;
  line-height: 2;
  @include sp {
    font-size: 18px;
  }

  &--long {
    font-size: 18px;
    line-height: 2;
    @include sp {
      font-size: 16px;
    }
  }
}
