html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.wf-loading {
    opacity: 0;
    pointer-events: none;
  }

  &.wf-active {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 150ms ease;
  }

  &.layout-fixed {
    min-width: $base-width;
  }
}

body {
  margin: 0;
  font-family: "source-han-sans-japanese", sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: .02em;
  color: #222;
  background: #fff;
  @include sp {
    font-size: 14px;
    line-height: (26/14);
  }

  @include en {
    font-family: "acumin-pro", sans-serif;
    line-height: (28/15);
  }
}

[lang="en"] {
  font-family: "acumin-pro", sans-serif;
  line-height: (28/15);
}


.icon-newwin { margin-left: .35em; }

.icon-global-color { margin-right: .35em; }

strong {
  font-weight: 500;
}

