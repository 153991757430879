.p-brand-mv2 {
  position: relative;
  margin: 0 -20px 20px;
  padding-top: 1px;
  max-width: none;

  &__items {
    border-bottom: 1px solid #e6e6e6;
  }

  button {
    @include input-reset;
  }

  img {
    @include sp {
      max-width: 100%;
      height: auto;
    }
  }

  .slick-slide {
    position: relative;
    margin: 2px 1px;
    line-height: 1;
    max-width: 672px;
    overflow: hidden;
    outline: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      opacity: .3;
      transition: all 600ms ease;
      pointer-events: none;
    }

    &.slick-active {
      &:after { opacity: 0; }
    }
  }

  //.slick-list {
  //  overflow: visible;
  //}

  .slick-dots {
    @include inline-block-list;
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 12px;

    li {
      margin: 0 10px;
      opacity: 1;

      &.slick-active {
        opacity: 1;

        button {
          background: #e40b2a;
        }
      }
    }

    button {
      width: 12px;
      height: 0;
      padding-top: 12px;
      background: #d9d9d9;
      border-radius: 6px;
      outline: none;
      overflow: hidden;
      vertical-align: bottom;
      cursor: pointer;
    }
  }
}
