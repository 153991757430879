.p-brand-mv4 {
  position: relative;
  margin: 0 -20px;
  padding-top: 1px;
  padding-bottom: 52px;
  max-width: none;

  &__hero {
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  &__items {
    position: relative;
    margin-top: 10px;

    .__item {
      margin: 0 5px;
      width: 24vw;
      outline: none;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }

    button { @include input-reset; }

    .slick-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 7vw;
      background: rgba(black, .2);
      overflow: hidden;
      text-indent: -999em;
      transition: 300ms;
      cursor: pointer;
      outline: none;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .slick-next {
      right: 0;
      &:before {
        transform: rotate(45deg);
      }
    }

    .slick-prev {
      left: 0;
      &:before {
        transform: rotate(-135deg);
      }
    }

    .slick-dots {
      @extend %mv-slick-dots;
      li { margin: 0 6px; }
      button { background: #ccc; }
    }
  }
}
