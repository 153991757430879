%mv-slick-dots {
  @include inline-block-list;
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 12px;

  li {
    margin: 0 10px;
    opacity: .4;
    &.slick-active { opacity: 1; }
  }

  button {
    width: 12px;
    height: 0;
    padding-top: 12px;
    background: #fff;
    border-radius: 6px;
    outline: none;
    overflow: hidden;
    vertical-align: bottom;
    cursor: pointer;
  }
}

.p-brand-mv {
  position: relative;
  margin: 0 -20px;
  padding: 39px 0 48px;
  max-width: none;
  background: #e8e8e8 url(../img/top_mv_bg.jpg) no-repeat 50% 50%;
  background-size: cover;
  overflow: hidden;
  * { outline: none; }
  @include sp {
    display: flex;
    min-height: 510px;
    padding: 40px 0;
    background-position: 100% 50%;
  }

  &__items {
    margin: auto;
    padding: 0 20px;
    text-align: center;
    @include sp {
      width: 100%;
    }
    a {
      display: inline-table;
      margin: auto;
      vertical-align: bottom;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  button { @include input-reset; }
  .slick-list { overflow: visible; }
  .slick-dots { @extend %mv-slick-dots; }
}

.p-brand-mv-movie {
  position: relative;
  margin: 0 -20px;
  max-width: none;
  background: #e8e8e8;
  overflow: hidden;

  img,
  video {
    display: block;
    width: 100%;
    height: auto;
  }
}
