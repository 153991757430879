.p-brand-mv3 {
  position: relative;
  margin: 0 -20px;
  padding-top: 1px;
  max-width: none;

  button {
    @include input-reset;
    outline: none;
    cursor: pointer;
  }

  img {
    @include sp {
      max-width: 100%;
      height: auto;
    }
  }

  .slick-slide {
    line-height: 1;
    text-align: center;
    overflow: hidden;

    a {
      display: inline-block;
      border-left: 2px solid white;
      border-right: 2px solid white;
      vertical-align: bottom;
      @include sp {
        border: none;
      }
    }
  }

  .slick-list {
    background: #e8e8e8 url(../img/top_mv3_bg.jpg) no-repeat 50% 50%;
    background-size: cover;
    overflow: visible;
    @include sp {
      background: none;
      overflow: hidden;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    line-height: 1;
    @include sp {
      margin-top: 14px;
    }

    li {
      flex: 0 1 110px;
      position: relative;
      margin-left: 5px;
      &:first-child { margin-left: 0; }
      @include sp {
        margin-left: 4px;
        &:first-child { margin-left: 0; }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .5;
        transition: all 200ms ease;
      }

      &.slick-active {
        &:after { opacity: 0; }
      }

      img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
      }
    }
  }
}
