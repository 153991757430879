.l-category-header {
  position: relative;
  margin: 0 -20px 80px;
  max-width: none;
  text-align: center;
  background: #ddd;
  background-size: cover;
  @include sp {
    margin-bottom: 20px;
    text-align: left;
    background: none;
  }

  $pages: entertainment, safety, cm, group, research, csr, ir;
  @each $page in $pages {
    &--#{$page} {
      background: #ddd url(../img/category_header_#{$page}.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
    @include sp {
      &--#{$page} { background: none; }
      &--#{$page} &__title:after {
        background: #ddd url(../img/category_header_#{$page}.jpg) no-repeat 50% 50%;
        background-size: cover;
      }
    }
  }

  &--recipe {
    display: flex;
    margin-bottom: 0;
    height: 450px;
    background: #ddd url(../img/category_header_recipe.jpg) no-repeat 50% 50%;
    background-size: cover;
    @include sp {
      text-align: center;
      background-image: url(../img/category_header_recipe@sp.jpg);
      background-size: cover;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: $base-width;
    height: 450px;
    box-sizing: border-box;
    @include sp {
      display: block;
      height: auto;
    }
  }

  &--recipe &__inner {
    width: 378px;
    height: 378px;
    background: #fff;
    border-radius: 50%;
    @include sp {
      display: flex;
      width: 313px;
      height: 313px;
    }
  }

  &__title {
    color: $color-red;
    font-size: 32px;
    font-weight: 200; // light
    letter-spacing: .1em;
    line-height: 1.4;
    @include sp {
      padding: 20px 20px 0;
      font-size: 20px;
      text-align: center;
      &:after {
        content: '';
        display: block;
        margin: 20px -20px 0;
        height: 0;
        padding-top: (208/375)*100%;
        background: #ddd;
      }
    }
  }

  &--recipe &__title {
    padding: 0;
    font-size: 36px;
    @include sp {
      font-size: 30px;
      &:after { content: none; }
    }
  }

  &__lead {
    margin-top: 35px;
    letter-spacing: .1em;
    @include sp {
      margin: 20px 0;
      padding: 0 20px;
      line-height: (30/16);
      letter-spacing: .07em;
    }
  }

  &--recipe &__lead {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 14px;
    @include sp {
      margin: 0 0 1em;
      padding: 0;
      font-size: 12px;
      &:after { content: none; }
    }
  }

  &--recipe &__search {
    margin-top: 15px;
    @include sp {
      margin-top: 10px;
    }

    form {
      position: relative;
      width: 270px;
      margin: auto;
      @include sp {
        width: 220px;
      }
    }

    input[type="text"] {
      @extend %button-chrome;
      width: 100%;
      padding-right: 50px;
      background: #faf7df;
      @include sp {
        padding: 0 40px 0 15px;
      }
    }

    button {
      @include input-reset;
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      font-size: 32px;
      line-height: 1;
      overflow: hidden;
      @include sp {
        width: 40px;
        font-size: 24px;
      }
    }
  }
}