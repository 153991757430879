.c-recipe-list-index {
  //margin-bottom: 80px;
  margin-top: -30px;
  &__items {
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  li {
    position: relative;
    flex: 1 1 auto;
    margin-left: 40px;
    &:first-child { margin-left: 0; }
    @include sp {
      flex-basis: calc(100% - 40px);
      margin-bottom: 40px;
      margin-left: 20px;
      margin-right: 20px;
      &:first-child { margin-left: 20px; }
    }
  }

  .__caption {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    margin-top:20px;
  }

  a {
    display: block;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #010101;
      //background: rgba(black, .2);
      z-index: 1;
    }

    &:hover {
      .__text {
        > span {
          color: #fefefe;
          background: rgba(black, .5);
        }
      }
    }
    .__image {
      img {
        width: 100%;
        height: auto;
        vertical-align: bottom;
      }
    }
  
    .__text {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      > span {
        width: 145px;
        padding: 6px;
        background: rgba(white, .8);
        letter-spacing: .02em;
        font-size: 15px;
        font-weight: 500; // medium
        text-align: center;
        transition: all 200ms ease;
        box-sizing: border-box;
        @include sp {
          // width: 112px;
          // padding: 1px;
          font-size: 14px;
        }
      }
    }
  }
} 

/*
.c-recipe-list-index_old {
  //margin-bottom: 80px;

  &__items {
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  li {
    position: relative;
    flex: 1 1 auto;
    margin-left: 1px;
    &:first-child { margin-left: 0; }
    @include sp {
      flex-basis: calc((100% - 1px) / 2);
      margin-top: 1px;
      margin-left: 1px;
      &:nth-child(2n+1) { margin-left: 0; }
      &:nth-child(1),
      &:nth-child(2){ margin-top: 0; }
    }
  }

  .__image {
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  .__text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > span {
      width: 145px;
      padding: 6px;
      background: rgba(white, .8);
      letter-spacing: .02em;
      font-size: 15px;
      font-weight: 500; // medium
      text-align: center;
      transition: all 200ms ease;
      box-sizing: border-box;
      @include sp {
        width: 112px;
        padding: 1px;
        font-size: 14px;
      }
    }
  }

  a {
    display: block;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #010101;
      //background: rgba(black, .2);
      z-index: 1;
    }

    &:hover {
      .__text {
        > span {
          color: #fefefe;
          background: rgba(black, .5);
        }
      }
    }
  }
} 
*/