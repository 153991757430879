.c-img-wrap {
  @include clearfix;
  @include margin-root;
  @include sp {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.c-img-nowrap-text {
  @include margin-root;
  overflow: hidden;
}

.c-img-caption {
  display: table;
  table-layout: fixed;
  width: 1%;
  @include sp {
    width: auto;
    .c-colbox & {
      margin-left: auto;
      margin-right: auto;
      img {
        display: block;
        margin: 0 auto .5em;
      }
    }
  }

  figcaption,
  .figcaption {
    color: #555;
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 1.5em;
    &:last-child { margin-bottom: 0; }

    @include sp {
      font-size: 12px;
      line-height: (22/12);
    }
  }
}

.c-img-left {
  float: left;
  margin-right: 40px;
  @include sp {
    float: none;
    display: block;
    margin-right: 0;
  }
}

.c-img-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
  @include sp {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
}

.c-img-right {
  float: right;
  margin-left: 40px;
  @include sp {
    float: none;
    display: block;
    margin-left: 0;
  }
}

.c-img-left,
.c-img-center,
.c-img-right {
  &.c-img-large:nth-child(2n) { margin-right: 0; }
  &.c-img-medium:nth-child(3n) { margin-right: 0; }
  &.c-img-small:nth-child(4n) { margin-right: 0; }
}

.c-img-full {
  width: 100%;
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }
  @include sp {
    text-align: left;
  }
}

.c-img-large {
  width: calc((100% - 40px) / 2);
  height: auto;
  img {
    max-width: 100%;
    height: auto;
  }
  @include sp { width: auto; }
}

.c-img-medium {
  width: calc((99.9% - 40px * 2) / 3);
  height: auto;
  img {
    max-width: 100%;
    height: auto;
  }
  @include sp { width: auto; }
}

.c-img-small {
  width: calc((100% - 40px * 3) / 4);
  height: auto;
  img {
    max-width: 100%;
    height: auto;
  }
  @include sp { width: auto; }
}
