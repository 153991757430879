.p-recipe-top-section {
  max-width: none;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  &:before,
  &:after {
    content: '';
    display: table;
    width: 100%;
  }

  > * {
    max-width: $base-width;
    margin-left: auto;
    margin-right: auto;
    html.layout-narrow & {
      max-width: $narrow-width;
    }
  }

  &__header {
    margin-bottom: 30px;

    .__title {
      margin-top: 70px;
      margin-bottom: 0;
      color: $color-red;
      font-size: 20px;
      font-weight: 300; // normal
      letter-spacing: .07em;
      line-height: 1.35;
      text-align: center;
    }

    .__button {
      margin-top: 20px;
      text-align: center;
    }

    .__link {
      margin-top: 15px;
      text-align: center;
    }
  }

  &__categories {
    background: url(../img/recipe_top_category_bg.jpg) 50% 50%;
    background-size: cover;
    max-width: none;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    @include sp {
      background: none;
      padding: 0;
    }

    &:before,
    &:after {
      content: '';
      display: table;
      width: 100%;
    }

    > * {
      max-width: $base-width;
      margin-left: auto;
      margin-right: auto;
      html.layout-narrow & {
        max-width: $narrow-width;
      }
    }
  }

  .__categories {
    display: flex;
    padding: 60px 0;
    @include sp {
      flex-direction: column;
      padding: 0;
    }

    &__box {
      flex: 1 1 50%;
      margin-left: 40px;
      padding: 15px 35px 5px;
      &:first-child { margin-left: 0; }
      background: rgba(white, .8);
      @include sp {
        position: relative;
        padding: 65px 35px;
        margin-left: 0;

        &:before {
          content: '';
          position: absolute;
          top: 40px;
          left: 20px;
          right: 20px;
          bottom: 40px;
          background: rgba(white, .8);
        }

        > * {
          position: relative;
        }
      }

      @include sp {
        &--pasta {
          background: url(../img/recipe_top_category_pasta_bg.jpg) 50% 50%;
          background-size: cover;
        }

        &--flour {
          background: url(../img/recipe_top_category_flour_bg.jpg) 50% 50%;
          background-size: cover;
        }
      }
    }

    &__title {
      text-align: center;
      font-weight: 300;
      font-size: 18px;
      color: $color-red;
    }

    &__links {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 14px;
      @include sp {
        &, caption, thead, tbody, tfoot, tr, th, td {
          display: block;
        }
      }

      th {
        width: 1%;
        white-space: nowrap;
        padding-right: 10px;
        vertical-align: top;
        text-align: right;
        font-weight: 300;
        border-right: 1px solid $color-red;
        @include sp {
          width: 100%;
          border: none;
          text-align: left;
          padding-right: 0;
        }
      }

      td {
        padding-left: 10px;
        @include sp {
          padding-left: 0;
        }

        ul {
          @include inline-block-list;

          > * {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            margin-right: 3px;
            padding: 0 5px;
            background: #fff;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 1;
          }

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // カテゴリーからレシピを探す
  &--category &__inner {
  }

  // 商品からレシピを探す
  &--product {
    padding-bottom: 30px;
    @include sp {
      padding-bottom: 0;
    }
  }

  // テーマ別レシピ
  &--theme {
    margin-bottom: 60px;
    background: #f4f4f3 url(../img/recipe_top_theme_bg.jpg) 50%;
    background-size: auto;
    @include sp {
      background: none;

      .__title {
        margin-top: 30px;
      }
    }
  }

  //// 学ぶ・楽しむ
  //&--entertainment {
  //  padding-bottom: 70px;
  //  background: #f0e9e7 url(../img/top_entertainment_bg.jpg) no-repeat;
  //  background-size: 100% auto;
  //}

  //// お知らせ
  //&--news &__header {
  //  padding-bottom: 20px;
  //  border-bottom: 1px solid #e5e5e5;

  //  .__title {
  //    font-size: 30px;
  //  }
  //}
}
