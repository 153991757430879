.p-product-detail {
  display: flex;
  @include sp {
    display: block;
  }

  &__pct,
  &__txt {
    flex: 0 0 auto;
    width: calc((100% - 40px) / 2);
    @include sp {
      width: 100%;
    }
  }

  &__pct {
    margin-right: 40px;
    text-align: center;
    @include sp {
      margin-bottom: 1.5em;
    }

    .__pct {
      img {
        vertical-align: bottom;
        max-width: 100%;
      }
    }

    .__labels {
      margin-top: 1.5em;
      line-height: 1;

      > * + * {
        margin-left: .5em;
      }
    }
  }

  &__txt {
    > * {
      margin-bottom: 1.5em;
      &:last-child { margin-bottom: 0; }
    }
  }

  &__eclinks {
    dt {
      color: #000;
      font-weight: 500; // medium
      font-size: 16px;
    }

    dd {
      margin-top: 6px;
      padding: 15px;
      border: 1px solid #e6e6e6;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          margin: 0 30px;
          line-height: 1;
        }

        a {
          display: block;
          transition: opacity 200ms ease;
          &:hover { opacity: .9; }
        }

        img {
          vertical-align: middle;
        }
      }
    }
  }

  &__banners {
    li {
      margin-bottom: 10px;
      &:last-child { margin-bottom: 0; }

      @include sp {
        text-align: center;
      }
    }

    a {
      display: inline-block;
      transition: opacity 200ms ease;
      &:hover { opacity: .9; }
    }

    img {
      max-width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  &__youtube {
    position: relative;
    width: 100%;
    padding-top: (315 / 560) * 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
