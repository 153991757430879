.p-release-article {
  &__date {
    margin-top: 70px;
    text-align: right;
    font-size: 13px;
    letter-spacing: .02em;
    color: #333;
    @include sp {
      margin-top: 25px;
    }
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 70px;
    color: $color-red;
    text-align: center;
    font-size: 26px;
    font-weight: 200; // light
    line-height: (46/26);
    @include sp {
      margin-top: 15px;
      margin-bottom: 60px;
      font-size: 20px;
      text-align: center;
      line-height: (35/20);
    }
  }

  &__body {
    @include margin-root;

    /* ニュースリリース用CSS（リニューアル前継続）
     --------------------------------------------- */

    table.data {
      border-collapse:collapse;
      margin:0 0 15px 0;
    }

    table.data th,
    table.data td {
      padding:5px;
      border:1px solid #cccccc;
    }

    table.data th,
    table.data td {
      padding:5px;
      border:1px solid #cccccc;
    }

    table.data th {
      background:#f1f3f5;
      font-weight:normal;
      text-align:left;
    }

    .center {
      text-align:center !important;
    }

    .right {
      text-align:right !important;
    }

    .box {
      padding:5px;
      border:1px solid #cccccc;
      margin-bottom:10px;
    }

    strong {
      font-weight:bold;
    }

    p.subtitle {
      display:inline;
      padding:3px 20px 3px 3px;
      line-height:1.5;
    }

    p.subtitle span {
      border:1px solid #000000;
      padding:2px 3px 0 2px;
    }

    img.photo {
      margin:10px;
    }

    /*上記のスタイルを打ち消したい場合に使用*/

    .not_bold {
      font-weight:normal !important;
    }

    .mg0 {
      margin-bottom:0 !important;
    }

    .not_indent {
      text-indent:0 !important;
    }

    .mark {
      text-indent:-1em !important;
      margin-left:1em !important;
    }

    .mark_half {
      text-indent:-0.5em!important;
      margin-left:0.5em!important;
    }

    .margin_left_1em {
      margin-left: 1em;
    }

    /*-------------------------------------090219追記*/
    #releasebox table {
      border-collapse: collapse;
      border: #999 solid 1px;
    }
    #releasebox td,#releasebox th {
      padding: 5px;
      border: #999 solid 1px;
    }
    #releasebox th {
      vertical-align: top;
    }
    #releasebox .align-top {
      vertical-align: top;
    }
    #releasebox table.bordernone {
      border-collapse: collapse;
      border: none;
    }
    #releasebox td.bordernone {
      border: none;
    }


    table.layout {
      border-collapse:collapse;
      margin:0 0 15px 0;
      border:none !important;
      background:none !important;
    }

    table.layout caption {
      font-weight:bold;
    }

    table.layout th,
    table.layout td {
      vertical-align:top;
      border:none !important;
      background:none !important;
    }
    table.layout th.nowrap,
    table.layout td.nowrap {
      white-space: nowrap;
    }
    table.layout th.nowrap {
      padding-right: 1em;
    }


    table.layout_dot {
      border:none !important;
      background:none !important;
    }

    table.layout_dot td {
      border-collapse:collapse;
      margin:0 0 15px 0;
      border-top-style: none;
      border-right-style: none;
      border-left-style: none;
      border-bottom-width: 1px;
      border-bottom-style: dotted;
      border-bottom-color: #999999;
      background:none !important;
      vertical-align:top;
      border-top-width: 0px;
      border-right-width: 0px;
      border-left-width: 0px;
    }

    table.layout_dot .no_border {
      border:none !important;
    }

    .font-i {
      font-style: italic;
    }

  }
}
