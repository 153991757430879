/**
 * 日清製粉グループ本社
 */

@import url(../fonts/nisshin/style.css);

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/_reset";
@import "foundation/_vars";
@import "foundation/_mixins";
@import "foundation/_base";

// ==========================================================================
// Layout
// ==========================================================================

@import "layout/_wrapper";
@import "layout/_footer";
@import "layout/_header";
@import "layout/_main";
@import "layout/_topicpath";
@import "layout/_localnav";
@import "layout/_category-header";
@import "layout/_special-news";

// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

// 本文
@import "object/component/_paragraph";

// グリッド
@import "object/component/_grid";

// 見出し
@import "object/component/_headings";
@import "object/component/_catch";

// 画像
@import "object/component/_images";

// 表組み
@import "object/component/_tables";

// コラムボックス
@import "object/component/_colbox";

// ボタン・リンク
@import "object/component/_button";
@import "object/component/_link";

// ラベル
@import "object/component/_labels";
@import "object/component/_entry-status";

// ナビゲーション
@import "object/component/_tab";
@import "object/component/_inpage-nav";
@import "object/component/_pager-nav";

// 扉ページ用インデックス
@import "object/component/_index-list";
@import "object/component/_ctop-index-list";

// レシピ系一覧
@import "object/component/_recipe-list";
@import "object/component/_recipe-list-index";

// 商品系一覧
@import "object/component/_product-list";
@import "object/component/_product-cat-list";

// ニュース系一覧
@import "object/component/_news-list";
@import "object/component/_release-nav";
@import "object/component/_release-list";

// サイト一覧
@import "object/component/_site-list";
@import "object/component/_site-list-colbox";

// フォームパーツ
@import "object/component/_form";

// その他
@import "object/component/_more-list";
@import "object/component/_index-banner";


// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

// 共通
@import "object/project/_recommend-sites"; // 「おすすめ関連サイト」
@import "object/project/_recommend-recipe"; // 「この商品を使ったおすすめレシピ」
@import "object/project/_recommend-site-list"; // 学ぶ・楽しむのカルーセル

// ブランドトップ
@import "object/project/_brand-mv";
@import "object/project/_brand-mv2";
@import "object/project/_brand-mv3";
@import "object/project/_brand-mv4";
@import "object/project/_brand-top-section";

// 企業トップ
@import "object/project/_company-top-section";

// レシピ
@import "object/project/_recipe";
@import "object/project/_recipe2";
@import "object/project/_recipe-search";
@import "object/project/_recipe-categories";
@import "object/project/_recipe-theme";
@import "object/project/_recipe-theme-list";
@import "object/project/_recipe-top-section";

// 商品情報
@import "object/project/_product-top-section";
@import "object/project/_product-detail";
@import "object/project/_product-nav";
@import "object/project/_product-search";
@import "object/project/_product-manage-pane";

// CM・キャンペーン
@import "object/project/_cm-campaign-list";

// 学ぶ・楽しむ
@import "object/project/_school-header";

// お客様窓口
@import "object/project/_faq-list";
@import "object/project/_faq-search";
@import "object/project/_faq-index";

// 安全・安心
@import "object/project/_safety-outline";
@import "object/project/_safety-nav";
@import "object/project/_safety-stepnav";

// グループについて
@import "object/project/_group-functions";
@import "object/project/_group-message";
@import "object/project/_group-idea";

// 研究開発
@import "object/project/_research-top-section";
@import "object/project/_research-paper-list";

// CSR
@import "object/project/_csr-old"; // 旧コンテンツ向け
@import "object/project/_csr-message";
@import "object/project/_csr-download-list";

// IR
@import "object/project/_ir-top-section";

// ニュースリリース
@import "object/project/_release-article";

// 利用規約
@import "object/project/_policy";

// サイトマップ
@import "object/project/_sitemap";

// 404 Not Found
@import "object/project/_404-top-section";


// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/_width";
@import "object/utility/_align";
@import "object/utility/_margin";
@import "object/utility/_text";
@import "object/utility/_br";

// styleguide ------------------------------------------------------
@import "_styleguide";

