.u-fz80p { font-size: 80%; }
.u-fz90p { font-size: 90%; }
.u-fz100p { font-size: 100%; }
.u-fz110p { font-size: 110%; }
.u-fz120p { font-size: 120%; }
.u-fz130p { font-size: 130%; }
.u-fz140p { font-size: 140%; }
.u-fz150p { font-size: 150%; }

.u-wb {
  word-break: break-all;
  word-wrap: break-word;
}