.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #333;
  background: $color-beige;
  transition: top 200ms ease;
  z-index: $z-index-header;
  box-shadow: 0 1px 0 rgba(0,0,0,.1);

  a:hover {
    text-decoration: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 2px solid $color-red;
    z-index: $z-index-header + 2;
  }

  @include sp {
    box-shadow: none;
  }

  &__menu-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    background: $color-beige;
    @include sp {
      position: fixed;
      top: $header-top-height-sp;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-y: auto;
      opacity: 0;
      transform: translateX(10%);
      transform: scale(.95);
      transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
      transform-origin: 50% 0 0;
      transition: all 400ms ease;
      pointer-events: none;
      .is-menu-open & {
        transform: translateX(0);
        transform: scale(1);
        transform: rotate3d(0, 1, 1, 0);
        opacity: 1;
        pointer-events: auto;
      }
      @at-root .is-menu-open {
        &, body { overflow: hidden; }
      }
    }

    &:before {
      content: '';
      display: block;
      height: $header-top-height;
      width: 1px;
      @include sp {
        content: none;
      }
    }
  }

  &__menu-toggler {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    height: $header-top-height-sp;
    z-index: $z-index-header + 2;
    cursor: pointer;

    > span:before,
    > span:after,
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 15px;
      width: 25px;
      height: 2px;
      background: $color-red;
      transition: all 500ms ease;
    }
    &:before {
      top: 20px;
    }
    > span:before {
      top: 29px;
      left: 15px;
      width: 13px;
    }
    > span:after {
      top: 29px;
      left: 15px + 13px;
      width: 12px;
    }
    &:after {
      top: 38px;
    }

    .is-menu-open & {
      &:before {
        transform: translateY(-10px);
        opacity: 0;
      }
      > span:before {
        left: 15px;
        width: 25px;
        transform: rotate(-45deg - 180deg);
        transition-delay: 120ms;
      }
      > span:after {
        left: 15px;
        width: 25px;
        transform: rotate(180deg + 45deg);
        transition-delay: 120ms;
      }
      &:after {
        transform: translateY(10px);
        opacity: 0;
      }
    }
    @include sp { display: block; }
  }

  @at-root html {
    padding-top: $header-top-height + $header-bottom-height;
    @include sp {
      padding-top: $header-top-height-sp;
    }
  }

  .is-scrolled & {
    // translateY で動かすとoverlayがうまくいかない
    top: -$header-top-height;
    @include sp {
      top: -$header-top-height-sp;
    }
  }

  .is-scroll-back & {
    top: 0;
  }

  //.is-scrolled.is-menu-open & {
  //  transition-duration: 0;
  //  transform: translateY($header-top-height);
  //  @include sp {
  //    transform: translateY($header-top-height-sp);
  //  }
  //}

  &__title {
    position: absolute;;
    top: 0;
    left: 0;
    flex-grow: 1;
    height: $header-top-height;
    z-index: $z-index-header + 1;
    @include sp {
      position: static;
      height: $header-top-height-sp;
      padding-right: $header-top-height-sp;
      background: none;
    }

    a {
      display: inline-flex;
      align-items: center;
      height: 100%;
    }

    span {
      display: inline-block;
      width: 351px;
      height: 0;
      padding-top: 25px;
      overflow: hidden;
      background: url(../img/logo.png) no-repeat;
      background-position: 0 50%;
      background-size: 100% auto;
      @include dpr2 {
        background-image: url(../img/logo@2x.png);
        background-size: 100% auto;
      }
      @include sp {
        width: 299px;
        margin-top: 2px;
        padding-top: 21px;
        background-image: url(../img/logo@sp.png);
        background-size: 100% auto;
      }

      @include en {
        width: 174px;
        padding-top: 25px;
        background: url(../img/logo-en.png) no-repeat;
        background-position: 0 50%;
        background-size: 100% auto;
        @include dpr2 {
          background-image: url(../img/logo-en@2x.png);
          background-size: 100% auto;
        }
        @include sp {
          width: 174px;
          padding-top: 25px;
          background-image: url(../img/logo-en@sp.png);
          background-size: 100% auto;
        }
      }
    }
  }

  &__helpnav,
  &__search,
  &__switchnav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1.25;
    height: $header-top-height;
    li { height: 100%; }
    a {
      display: inline-flex;
      padding: 0 12px;
      align-items: center;
      height: 100%;
      color: inherit;
      text-decoration: none;
      &:hover { color: $color-red; }
    }
    i { font-size: 16px; }
  }
  @include sp {
    &__helpnav   { order: 4; }
    &__search    { order: 1; }
    &__switchnav { order: 2; }
    &__gnav      { order: 3; }
  }

  &__helpnav {
    font-size: 13px;
    letter-spacing: .05em;
    @media (max-width: 1000px) {
      & { display: none; }
    }
    @include sp {
      display: block;
      background: #fff;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-shrink: 0;
      width: 100%;
      height: auto;
      li {
        flex-basis: 50%;
        flex-grow: 1;
        height: auto;
        a {
          display: flex;
          height: 55px;
          border-bottom: 1px solid $color-border;
          font-size: 13px;
          padding: 0 20px;
        }
        &:nth-child(2n) a {
          border-left: 1px solid $color-border;
        }
      }
    }
  }

  &__switchnav {
    font-size: 13px;
    letter-spacing: .05em;

    @include sp {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      height: 40px;
      margin-top: 12px;
      border-bottom: 1px solid $color-border;
    }

    li {
      border-left: 1px solid $color-border;
      @include sp {
        flex-grow: 1;
        &:first-child { border-left: none; }
      }

      body.site-product &:nth-child(1) a,
      body.site-company &:nth-child(2) a {
        background: #fff;
        border-bottom-color: transparent;
        &:after { opacity: 1; }
        @include sp {
          position: relative;
          background: none;
          opacity: 1;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: $color-red;
            transform: none;
          }
        }
      }
    }

    a {
      position: relative;
      @include sp {
        display: block;
        padding-top: 6px;
        text-align: center;
        width: 100%;
        opacity: .6;
      }

      &:after {
        @include icon($char-arrow-s);
        position: absolute;
        bottom: 2px;
        left: calc(50% - 6px);
        font-size: 11px;
        color: $color-red;
        opacity: 0;
        transform: rotate(90deg);
        transition: all 100ms ease;
        @include sp { content: none; }
      }

      &:hover {
        color: $color-red;
        &:after { opacity: 1; }
        @include sp { color: inherit; }
      }
    }
  }

  &__search {
    $a: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: ($a + 10px + 1px) * 2;
    font-size: 13px;
    line-height: 1;
    border-left: 1px solid $color-border;
    @include sp {
      width: 100%;
      height: auto;
      padding: 7px 20px;
      border-left: none;
      flex-shrink: 0;
    }

    form {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -($a + 1px);
      @include inline-block-list;
      background: transparent;
      white-space: nowrap;
      border: 1px solid transparent;
      border-radius: $a;
      transition: all 200ms ease;
      z-index: $z-index-header + 11;
      overflow: hidden;
      @include sp {
        position: static;
        border-color: $color-red;
        background: #fff;
        width: 100%;
        margin: 0;
        transition: none;
      }
    }
    &.is-open form {
      right: 5px;
      background-color: #fff;
      border-color: $color-red;
    }

    input[type="text"],
    button {
      @include input-reset;
      height: $a * 2;
      vertical-align: bottom;
      box-sizing: border-box;
      letter-spacing: normal;
    }

    input[type="text"] {
      padding: 0;
      width: 0;
      transition: all 200ms ease;
      outline: none;
      @include placeholder {
        display: inline-block;
        color: silver;
        line-height: 1.4;
      }
      @include sp {
        padding: 0 $a;
        width: calc(100% - #{$a} * 2);
        transition: none;
      }
    }
    &.is-open input[type="text"] {
      padding: 0 $a;
      width: $a * 24;
      @include sp {
        width: calc(100% - #{$a} * 2);
      }
    }

    button {
      width: $a * 2;
      color: $color-red;
      border-radius: $a;
      transition: text-indent 200ms ease;
      @include sp {
        text-indent: -10px;
        transition: none;
      }
      i {
        font-size: 32px;
        font-weight: normal; // icomoon
      }
    }
    &.is-open button {
      text-indent: -10px;
    }

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(black, .2);
      transition: opacity 200ms linear;
      pointer-events: none;
      opacity: 0;
      z-index: $z-index-header + 10;
      @include sp {
        display: none;
      }
    }
    &.is-open &__overlay {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &__gnav {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    font-weight: 500; // medium
    line-height: 20px;
    letter-spacing: .1em;
    background: #fff;
    @include tb {
      font-size: 14px;
      justify-content: stretch;
    }
    @include sp { display: block; }

    body.site-product &--company,
    body.site-company &--product {
      display: none;
    }

    &__item {
      @include tb {
        flex: 1 1 auto;
      }

      //&.has-megamenu > a:before {
      //  content: '…';
      //  display: inline-block;
      //  position: absolute;
      //  left: 50%;
      //  bottom: 6px;
      //  width: 2em;
      //  margin-left: -1em;
      //  text-align: center;
      //  line-height: 1;
      //  color: silver;
      //  pointer-events: none;
      //  @include sp { content: none; }
      //}

      /*
      &--1__old .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/recipe/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }
      */

      &--1 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/recipe/bg2.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--2 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/products/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
        background-position: 0 100%;
      }

      &--3 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/cm/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--4 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/entertainment/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--5 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/customer/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--6 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/safety/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--11 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/group/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--13 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/research/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--14 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/csr/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      &--15 .l-header__gnav__megamenu__title {
        background: url(../img/megamenu/ir/bg.jpg) no-repeat 50% 50%;
        background-size: cover;
      }

      > a {
        position: relative;
        display: inline-flex;
        height: 100%;
        width: 100%;
        min-width: 6em;
        padding: 31px 15px;
        color: inherit;
        text-decoration: none;
        justify-content: center;

        //background: rgba(black, .1);
        //box-shadow: inset 0 0 5px black;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          height: 2px;
          width: calc(60% - 15px * 2);
          opacity: 0;
          background: $color-red;
          transition: all 200ms ease;
        }

        &:after { left: calc(30% + 15px); }

        &:hover,
        &.is-megamenu-open {
          color: $color-red;
          &:after {
            opacity: 1;
            left: 15px;
            width: calc(100% - 15px * 2);
          }
        }

        @include tb {
          padding-left: 15px;
          padding-right: 15px;
        }

        @include sp {
          display: flex;
          justify-content: left;
          height: 55px;
          padding: 0 20px;
          border-bottom: 1px solid $color-border;
          align-items: center;
          &:after { content: none; }
        }
      }

      @for $i from 0 through 20 {
        body.gnav-#{$i} &.l-header__gnav__item--#{$i} a {
          &:after {
            opacity: 1;
            left: 15px;
            width: calc(100% - 15px * 2);
          }

          @include sp {
            color: $color-red;
          }
        }
      }
    }

    &__megamenu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      color: $color-black;
      background: $color-beige;
      font-size: 14px;
      width: 100%;
      z-index: 1;
      box-shadow: 0 -1px 0 rgba(0,0,0,.1);

      &__inner {
        display: flex;
        margin: auto;
        width: $base-width;
      }

      &__title {
        flex: 0 0 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 40px;
        color: #fff;
        background: rgba(black, .2);
        font-size: 32px;
        font-weight: 200; // light
        letter-spacing: .07em;
        line-height: (50/32);
      }

      &__body {
        flex: 1 1 auto;
        padding: 45px 0;
        font-weight: 300; // normal
        //background: rgba(black, .1);
        letter-spacing: .02em;

        .__col-3 {
          overflow: hidden;
          .__col {
            float: left;
            width: calc((99.9% - 40px * 2) / 3);
            margin-left: 40px;
            &:first-child { margin-left: 0; }
          }
        }

        .__col-2 {
          overflow: hidden;
          .__col {
            float: left;
            width: calc((100% - 40px * 1) / 2);
            margin-left: 40px;
            &:first-child { margin-left: 0; }
          }
        }

        //.__col-3 { column-count: 3; column-gap: 40px; }
        //.__col-2 { column-count: 2; column-gap: 40px; }
        
        .__col-recipe {
          @include clearfix;
          display:flex;
          flex-direction:column;
          
          &-1 {
            width:100%;
            margin-left: 30px;
            padding-bottom: 15px;
          }

          &-2 {
            width:100%;
            margin-left: 30px;
          }

          &-col-2 {
            display: flex;
            margin-left: -30px;
          }

          &-col {
            flex-grow: 1;
            margin-left: 30px;
            :nth-child(1) {
              flex-grow: 0;
            }
          }
        }
        /*
        .__col-recipe__old {
          @include clearfix;

          &-1 {
            float: left;
            width: calc(100% - 30px * 2 - 290px * 2);
          }

          &-2,
          &-3 {
            float: left;
            margin-left: 30px;
            width: calc((100% - 30px * 2 - 130px) / 2);
          }

          &-col-2 {
            display: flex;
            margin-left: -30px;
          }

          &-col {
            width: calc((100% - 30px * 2) / 2);
            margin-left: 30px;
          }
        }
        */
        .__col {
          //break-inside: avoid;
          //> :last-child {
          //  margin-bottom: 0;
          //}
        }


        /*
        .__recipe-list-index__old {

          li {
            position: relative;
          }

          a {
            display: block;
            width: 100%;
            background: #000;

            &:hover .__image {
              opacity: 1;
              transition: all 200ms ease;
            }
          }

          .__image {
            &:after { content: none; }
            opacity: .75;

            img {
              width: 100%;
              height: auto;
            }
          }

          .__text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 15px;
            color: #fff;
            font-weight: 500;
          }
        }

        */
        .__recipe-list-index {
          display:flex;
          justify-content:space-between;

          li {
            margin:0 5px;
            position: relative;
          }
          li:nth-child(1) {
            margin:0 5px 0 0;
          }
          li:last-child {
            margin:0 0 0 5px;
          }
          a {
            display: block;
            width: 100%;
            text-decoration: none;

            &:hover .__image {
              opacity: 0.75;
              transition: all 200ms ease;
            }
          }

          .__image {
            &:after { content: none; }
            opacity: 1;
            background: #000;
            margin-bottom: 5px;

            img {
              width: 100%;
              height: auto;
            }
          }

          .__text {
            // position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            color: #000;
            font-weight: 500;
          }
        }
        .__title-nb {
          margin-bottom: 15px;
          color: $color-red;
          font-size: 18px;
        }

        .__title {
          @extend .__title-nb;
          padding-bottom: 10px;
          border-bottom: 1px solid $color-border;
        }

        .__title-2 {
          @extend .__title;
          color: inherit;
        }

        .__title-3 {
          color: $color-red;
          font-size: 13px;
          margin-bottom: 12px;
          padding-bottom: 5px;
          border-bottom: 1px solid #f4aca5;
        }

        .__image-nb {
          display: flex;
          justify-content: space-between;
          align-items: left;
          margin-bottom: 15px;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            pointer-events: none;
          }
          img {
            display: block;
            max-width: 100%;
            height: auto;
          }
          a {
            transition: all 200ms ease;
            &:hover { opacity: .75; }
          }
        }

        .__image {
          @extend .__image-nb;
          //background: #e6e6e6;
          &:after {
            border: 1px solid #e6e6e6;
          }
        }

        .__text {
          margin-bottom: 15px;
        }

        .__list {
          li {
            border-bottom: 1px solid $color-border;
            &:first-child {
              border-top: 1px solid $color-border;
            }
            > a {
              display: flex;
              //min-height: 63px;
              height: 63px;
              align-items: center;
              color: inherit;
              text-decoration: none;
              transition: all 200ms ease;
              &:hover {
                background: rgba(black, .1);
              }
              > img {
                display: block;
                flex: 0 0 63px;
                margin-right: 12px;
              }
              > span {
                flex: 0 1 auto;
                padding: 5px 0;
              }
            }
          }
        }

        .__list-2 {
          li {
            margin-bottom: .8em;
          }
          a {
            display: inline-block;
            color: inherit;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .__list-3 {
          dt {
            img {
              display: block;
            }
          }
          dd ul {
            @extend .__list;
            li {
              &:first-child {
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}

.l-megamenu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, .4);
  opacity: 0;
  transition: all 400ms ease;
  pointer-events: none;
  z-index: 1;

  html.is-megamenu-overlay-open & {
    opacity: 1;
  }
}

@media (max-width: #{$base-width}) {
  .l-header__gnav__megamenu,
  .l-megamenu-overlay {
    display: none !important;
  }
}

.l-gdpr {
  position: fixed;
  z-index: 10;
  left:0;
  bottom: 0;
  background: #faf7df;
  height: auto;
  width: 100%;
  padding: 18px 50px;
  margin:auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include sp {
    flex-direction: column;
    padding: 20px 14px;
  }
  &__msg {
    font-size: 13px;
    margin-right:36px;
    @include sp {
      margin-right: auto;
      margin-bottom: 10px;
    }
  }
  &__btn {
    white-space: nowrap;
    flex: 1 0 auto;
    @include sp {
      flex-basis: auto;
    }
  }
  &__margin {
    bottom:150px !important;
    @include sp {
      bottom: 250px !important;
    }
  }
}