.p-recipe-categories {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid $color-border;
  @include sp {
    padding-top: 0;
    border-top: none;
  }

  .__category {
    position: relative;
    margin-bottom: 20px;
    &:last-child { margin-bottom: 0; }
    @include sp {
      margin: 30px 20px;
      padding-top: 30px;
      border-top: 1px solid $color-border;
    }
  }

  .__title {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    font-weight: 500; // medium
    @include sp {
      position: static;
    }
  }

  .__links {
    padding-left: 160px + 20px;
    font-size: 13px;
    @include sp {
      padding-left: 0;
      > ul, > dl {
        margin-top: 15px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: .25em;
      left: 160px;
      bottom: .25em;
      border-left: 1px solid $color-red;
      @include sp {
        content: none;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    dl {
      dd, ul {
        display: inline;
      }
    }

    dt,
    dd {
      display: inline-block;
    }

    dt {
      font-weight: 500; // medium
      margin-right: 1em;
      @include sp {
        margin-right: 0;
      }
    }

    ul {
      @include inline-block-list;
    }

    li {
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
