.c-release-list {
  margin-top: 20px;
  margin-bottom: 80px;

  &--narrow,
  html.layout-narrow &--narrow {
    max-width: 640px;
  }

  &__items {
    margin-bottom: 50px;
    &:last-child { margin-bottom: 0; }
    font-size: 14px;
    line-height: (25/14);

    li {
      &:nth-child(n+2) {
        margin-top: 30px;
        @include sp {
          margin-top: 15px;
        }
      }
      a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
      }

      a:hover .__text {
        text-decoration: underline;
      }

      .__meta {
      }

      .__tag {
        margin-right: 1.5em;
        font-size: (13/14)*100%;
        color: #808080;

        &:before {
          content: '';
          display: inline-block;
          margin-right: .5em;
          width: 13px;
          height: 13px;
          background: currentColor;
          border-radius: 50%;
          vertical-align: -2px;
        }
        &[data-tag="company"]:before { background: #e60020; }
        &[data-tag="products"]:before { background: #ffae00; }
        &[data-tag="campaign"]:before { background: #a066d2; }
        &[data-tag="ir"]:before { background: #42b6e1; }
      }

      .__date {
        font-size: (12/14)*100%;
        color: #808080;
      }

      .__title {
        .c-label-pdf {
          margin-right: .6em;
        }
      }

      .__pdfsize {
        font-size: (12/14)*100%;
        color: #808080;
      }
    }
  }

  &--col-2 &__items {
    column-count: 2;
    column-width: 280px;
    column-gap: 40px;
  }

  &--col-3 &__items {
    column-count: 3;
    column-width: 280px;
    column-gap: 40px;
  }
}
