.p-policy {
  .c-hd-4 {
    margin:1em auto;
  }
  .c-ol.main {
    list-style-type: none;
    counter-reset:item;
    padding-left:0;
    &>li {
      margin-bottom:2em;
      &:before {
        display: inline-block;
        width:3em;
        counter-increment:item;
        content: '（'counter(item)'）';
      }
      ul {
        padding-left: 5em;
      }
      p {
        padding-left:3em;
      }
    }
  }
}