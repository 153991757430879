.p-faq-list {
  border-top: 1px solid #d9d9d9;
  dt {
    position: relative;
    padding: 12px 40px 12px 50px;
    border: 1px solid #d9d9d9;
    border-top: none;
    cursor: pointer;
    transition: all 400ms ease;

    &.is-open {
      background: #f2f2f2;
    }

    // Q
    &:before {
      content: 'Q';
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -11px;
      width: 22px;
      height: 22px;
      color: #fff;
      background: $color-red;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      border-radius: 50%;
      font-family: "acumin-pro-condensed", sans-serif;
      font-weight: 600; // acumin-pro-condensed semibold
    }

    &:after,
    > span:before,
    > span:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
    }

    &:after {
      margin-top: -11px;
      width: 21px;
      height: 21px;
      background: #ccc;
      transform: rotate(270deg);
      transition: all 400ms ease;
    }

    &.is-open:after {
      background: #808080;
      transform: rotate(0deg);
    }

    // +
    > span:before,
    > span:after {
      width: 11px;
      height: 1px;
      margin-top: -1px;
      margin-right: 5px;
      background: #fff;
      transition: all 400ms ease;
      z-index: 1;
    }

    > span:before {
      transform: rotate(90deg);
    }

    &.is-open > span:before {
      transform: rotate(90deg) translateX(-8px);
      opacity: 0;
    }

    > span:after {
      transform: rotate(0deg);
    }
  }

  dd {
    @include margin-root;
    display: none;
    padding: 25px 20px 25px 50px;
    border: 1px solid #d9d9d9;
    border-top: none;
  }
}
