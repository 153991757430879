.u-mb0 { margin-bottom: 0 !important; }

@for $i from 0 through 20 {
  .u-mt#{$i * 5} { margin-top: $i * 5px !important; }
  .u-ml#{$i * 5} { margin-left: $i * 5px !important; }
  .u-mr#{$i * 5} { margin-right: $i * 5px !important; }
  .u-mb#{$i * 5} { margin-bottom: $i * 5px !important; }

  @include sp {
    [class*="u-mt"].u-mt#{$i * 5}sp { margin-top: $i * 5px !important; }
    [class*="u-ml"].u-ml#{$i * 5}sp { margin-left: $i * 5px !important; }
    [class*="u-mr"].u-mr#{$i * 5}sp { margin-right: $i * 5px !important; }
    [class*="u-mb"].u-mb#{$i * 5}sp { margin-bottom: $i * 5px !important; }
  }
}

