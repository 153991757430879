.p-safety-stepnav {
  margin: 80px auto;
  background: #f2f2f2;
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
    &, html.layout-narrow & {
      max-width: none;
    }
  }

  &__inner {
    display: flex;
    height: 50px;
    align-items: center;

    > * {
      flex: 1 1 30%;
      text-align: center;
      font-size: 13px;
      @include sp {
        flex: 1 1 auto;

        &:first-child,
        &:last-child {
          flex: 1 1 7%;
        }
      }

      &:first-child {
        text-align: left;
        font-size: 15px;

        & a:before {
          @include icon($char-arrow-m);
          position: relative;
          top: 2px;
          display: inline-block;
          margin-right: 10px;
          font-size: 18px;
          color: $color-red;
          transform: rotate(180deg);
        }
      }

      &:last-child {
        text-align: right;
        font-size: 15px;

        & a:after {
          @include icon($char-arrow-m);
          position: relative;
          top: 2px;
          display: inline-block;
          margin-left: 10px;
          font-size: 18px;
          color: $color-red;
          transform: rotate(0);
        }
      }

      a {
        display: inline-block;
        padding: 5px 10px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        > span {
          @include sp {
            display: none;
          }
        }
      }
    }
  }
}
