.c-tab {
  margin-top: 60px;
  margin-bottom: 40px;

  &__items {
    @include inline-block-list;
    text-align: center;
    > * {
      margin: 0 15px 20px;
      min-width: 17%;
    }
    a {
      width: 100%;
      @extend %button-chrome;
      text-decoration: none;
      &:hover {
        @extend %button-chrome-hover;
        text-decoration: none;
      }
    }
  }
}
