.c-index-banner {
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
  }

  picture, img {
    @include sp {
      max-width: 100%;
    }
  }
}
