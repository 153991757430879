.c-recommend-recipe {
  margin-bottom: 60px;
  &:last-child { margin-bottom: 0; }

  &__title {
    max-width: $base-width;
    margin: 0 auto 30px;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 500; // medium
    letter-spacing: .05em;
    border-bottom: 1px solid #d9d9d9;
  }
}

