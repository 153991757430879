.p-recipe {
  font-size: 14px;

  &__column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp { display: block; }

    &--rev {
      flex-direction: row-reverse;
    }
  }

  &__row {
    flex: 0 1 calc((100% - 40px) / 2);
    flex-basis: calc((100% - 40px) / 2); // IE11
    margin-bottom: 30px;
    @include sp { margin-left: 0; }
  }

  &__mv {
    @include sp {
      margin-left: -20px;
      margin-right: -20px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &__movie {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: (9/16*100%);

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__time {
    float: left;
    > * { vertical-align: top; }
  }

  &__share {
    text-align: right;
    margin-bottom: 45px;

    ul {
      @include inline-block-list;
    }

    li {
      margin-left: 15px;
      font-size: 20px;
      vertical-align: top;
      line-height: 1;
    }

    a {
      display: inline-block;
      text-decoration: none;
      width: 1.6em;
      height: 1.6em;
      border-radius: 50%;
      overflow: hidden;
      vertical-align: top;
    }

    [class^="icon-"] {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .icon-facebook {
      color: #fff;
      background: #3a5796;
      &:before {
        position: relative;
        right: -3px;
      }
    }

    .icon-twitter {
      color: #fff;
      background: #0d9ff0;
      font-size: (18/20)*100%;
    }

    .icon-line {
      color: #fff;
      background: #2cba40;
    }
  }

  &__howto {
    clear: both;

    &__title {
      @extend .c-hd-2;
      margin-top: 50px;
      font-size: 18px;
    }

    &__title-sub {
      @extend .c-hd-3;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    &__prep {
      margin-bottom: 60px;
    }

    &__instructions {
      margin-bottom: 30px;
    }

    &__more {
      margin-bottom: 10px;
    }

    &__onepoint {
      margin-bottom: 30px;

      > dt {
        margin-bottom: 5px;
        color: $color-red;
        font-family: "acumin-pro";
        font-size: 18px;
        letter-spacing: .1em;

        &:before {
          content: '';
          display: inline-block;
          margin-right: 10px;
          width: 21px;
          height: 30px;
          background: url(../img/icon_onepoint.png) no-repeat;
          background-size: contain;
          vertical-align: middle;
        }
      }

      > dd {
        padding: 20px 25px;
        background: #f2f2f2;
      }
    }

    &__more,
    &__instructions,
    .supplementList {
      .hdTxt {
        margin: 10px auto;
        font-size: 14px;
        color: #000;
      }

      .pctList {
        display: flex;
        flex-wrap: wrap;

        li {
          margin-right: 5px;
          margin-bottom: 5px;
          &:last-child {
            margin-right: 0;
          }
        }

        img {
          display: block;
        }
      }

      .instruction,
      .list {
        position: relative;
        margin-bottom: 0;
        padding: 20px 0;
        border-bottom: 1px solid #e5e5e5;

        &:first-child {
          border-top: 1px solid #e5e5e5;
        }

        &:after {
          content: '';
          clear: both;
          display: block;
        }

        .number {
          position: absolute;
          left: 0;
          font-size: 28px;
          font-family: "acumin-pro";
          font-weight: 200;
          line-height: 1;
          color: $color-red;
        }

        .pct {
          float: right;
          max-width: 25%;
          margin-left: 15px;

          img {
            max-width: 100%;
            height: auto;
          }
        }

        .txt,
        .pctList,
        .caption {
          margin-left: 34px;
        }

        .caption {
          margin-bottom: 10px;
        }

        .pctList {
          display: flex;
          > * {
            width: 28%;
            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  &__products {
    &__title {
      @extend .c-hd-3;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    &__products {
      margin-bottom: 30px;
      border-top: 1px solid #e5e5e5;

      li {
        a {
          padding: 5px 0;
          border-bottom: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          text-decoration: none;
          img {
            display: block;
            margin-right: 20px;
            border: 1px solid #e5e5e5;
          }
        }
      }
    }

    &__banners {
      margin-bottom: 60px;

      li {
        margin-bottom: 10px;
        &:last-child { margin-bottom: 0; }
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__ingredients {
    position: relative;

    &__title {
      @extend .c-hd-2-nb;
      margin-top: 50px;
      margin-bottom: 22px;
      font-size: 18px;
    }

    &__amount {
      position: absolute;
      top: .25em;
      right:0;
      font-weight: bolder;
    }

    &__table {
      table {
        width: 100%;
        border: 1px solid #e5e5e5;
      }

      tr {
        &:nth-child(2n+1) {
          th, td {
            background: #f7f7f7;
          }
        }
      }

      th,
      td {
        font-weight: inherit;
        padding: 6px 20px;
      }

      td {
        padding-left: 0;
        text-align: right;
      }
    }
  }

  &__related-recipes {

    &__title {
      @extend .c-hd-2-nb;
      margin-top: 50px;
      margin-bottom: 15px;
      font-size: 18px;
    }
  }
}
