.c-recommend-site-list {
  clear: both;
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
    overflow-x: scroll;
  }

  &__items {
    margin: -10px;
    font-size: 14px;
    line-height: (20/14);
    @include sp {
      margin: 0;
      @include inline-block-list;
    }

    li {
      position: relative;
      margin: 10px;
      @include sp {
        margin: 0 20px 0 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: box-shadow 200ms ease;
        pointer-events: none;
      }

      &:hover:after {
        box-shadow: 0 2px 10px rgba(black, .25);
      }

      a {
        display: table;
        width: 100%;
        color: inherit;
        background: #fff;
        text-decoration: none;
        transition: box-shadow 300ms ease;
        outline: none;
        &:hover {
          box-shadow: 0 2px 10px rgba(black, .25);
          @include sp { box-shadow: none; }
        }
      }

      .__image {
        display: table-row;
        img {
          display: block;
          width: 100%;
          height: auto;
          @include sp {
            width: auto;
          }
        }
      }
    }
  }

  .slick-list {
    @include sp {
      overflow: visible;
    }
  }

  .slick-arrow {
    @include input-reset;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 0;
    margin-top: -16px;
    padding-top: 32px;
    overflow: hidden;
    border-radius: 0;
    outline: none;
    transition: all 200ms ease;
    cursor: pointer;

    &:hover { transform: scale(1.1); }
    &:active { transform: scale(1.05); }

    &:before {
      @include icon($char-arrow-m);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      font-size: 24px;
      color: $color-red;
    }
  }

  .slick-prev {
    left: -38px;
    &:before {
      transform: rotate(180deg) translateY(4px);
    }
  }

  .slick-next {
    right: -38px;
    &:before {
      transform: rotate(0) translateY(4px);
    }
  }
}

