.p-product-manage-pane {
  //position: fixed;
  //left: 0;
  //bottom: 0;
  //width: 100%;
  padding: 20px;
  background: rgba(black, .8);
  font-size: 22px;
  text-align: center;
  box-sizing: border-box;

  &__approve,
  &__publish,
  &__cancel {
    @include input-reset;
    margin: 0 10px;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    text-indent: 1em;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 1em;
  }

  &__approve,
  &__publish {
    background: #7cff8c;
  }
}
