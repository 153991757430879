.p-product-search {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 675px;
  margin: auto;
  @include sp {
    flex-direction: column;
    max-width: none;
  }

  &__title {
    display: none;
    margin-bottom: 5px;
    font-size: 16px;
    @include sp {
      display: block;
    }

    i {
      position: relative;
      top: -2px;
      font-size: 24px;
      margin-right: 5px;
      vertical-align: middle;
      color: $color-red;
    }
  }

  > * {
    flex: 1 1 auto;

    & + * {
      margin-left: 16px;
      @include sp {
        margin-left: 0;
        margin-top: 10px;
        &:last-child {
          margin-top: 20px;
        }
      }
    }

    > * {
      width: 100%;
    }
  }
}
