.p-company-top-section {
  max-width: none;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  &:before,
  &:after {
    content: '';
    display: table;
    width: 100%;
  }

  > * {
    max-width: $base-width;
    margin-left: auto;
    margin-right: auto;
    html.layout-narrow & {
      max-width: $narrow-width;
    }
  }

  &__header {
    margin-bottom: 50px;
    text-align: center;

    .__title {
      @extend .c-hd-1;
      margin-bottom: 0;
    }

    .__button {
      margin-top: 20px;
    }

    .__link {
      margin-top: 15px;
    }

    .__lead {
      margin-top: 20px;
    }
  }

  &__footer {
    margin: 65px auto;
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // 採用情報
  &--recruit {
    position: relative;
    padding-bottom: 110px;
    text-align: center;
    background: #f6f2f1 url(../img/top_recruit_bg.jpg) no-repeat 100% 50%;
    background-size: auto 100%;
    @include sp {
      padding-bottom: 70px;
      background-image: none;
    }
  }

  &--recruit &__header {
    margin-bottom: 30px;
    font-size: 16px;
    @include sp { margin-top: 40px; }

    .__title {
      margin-bottom: 20px;
      @include sp { font-size: 28px; }
    }

    .__banner {
      position: absolute;
      top: 0;
      left: 2%;
      width: 28%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img { max-width: 100%; }
      @include sp {
        position: static;
        margin-bottom: 40px;
        width: 100%;
      }
    }
  }

  // ニュースリリース
  &--release &__header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;

    .__title {
      font-size: 30px;
    }
  }

  // 英語版
  &--index {
    padding-top: 80px;
  }

  // 英語版ニュースリリース
  &--release-en &__header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;

    .__title {
      font-size: 18px;
      margin-top: 0;
    }
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  %icon-circle-arrow {
    @include icon($char-arrow-s);
    position: relative;
    top: -5px;
    display: inline-block;
    font-size: 11px;
    width: 28px;
    height: 28px;
    color: inherit;
    border-radius: 14px;
    border: 1px solid currentColor;
    text-align: center;
    line-height: 26px;
    text-indent: 3px;
    box-sizing: border-box;
  }

  .__panels {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    max-width: none;
    margin-left: -20px;
    margin-right: -20px;

    li {
      position: relative;
      a {
        display: block;
        height: 100%;
        background: rgba(black, .1);
      }
    }

    .__image {
      display: block;
      height: 100%;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        transition: transform 250ms;
      }
    }
    a:hover .__image img {
      transform: scale(1.05);
    }

    .__text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &--col-1 li {
      width: 100%;

      .__text {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $color-red;

        &:after {
          margin-top: 30px;
          @extend %icon-circle-arrow;
        }
      }

      .__title {
        font-size: 36px;
        line-height: (52/36);
        font-weight: 200; // light
        letter-spacing: .15em;
      }

      .__lead {
        margin-top: 20px;
        font-size: 16px;
        line-height: (30/16);
        letter-spacing: .1em;
        color: $color-black;
        > span {
          display: block;
        }
      }
    }

    &--col-2 li {
      width: 50%;
      @include sp { width: 100%; }

      .__text {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;

        &:after {
          margin-top: 30px;
          @extend %icon-circle-arrow;
        }
      }

      .__title {
        font-size: 36px;
        line-height: (52/36);
        font-weight: 200; // light
        letter-spacing: .15em;
      }

      .__lead {
        margin-top: 20px;
        font-size: 16px;
        line-height: (30/16);
        letter-spacing: .1em;
        > span {
          display: block;
        }
      }
    }

    &--col-3 li {
      width: (100% / 3);
      @include sp {
        width: 100%;
      }

      .__text {
        position: absolute;
        top: 50%;
        left: 25px;
        margin-top: -1em;
        font-size: 20px;
        font-weight: 200; // light
        color: #fff;
        letter-spacing: .1em;
        line-height: 1.35;
        &:after {
          @include icon($char-arrow-s);
          position: relative;
          top: -1px;
          margin-left: 10px;
          font-size: 13px;
        }
      }
    }
  }
}
