.p-research-paper-list {
  line-height: (28/15);
  li {
    padding: .75em 0;
    border-top: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: 1px solid #d9d9d9;
    }
    span {
      display: block;
      font-size: (13/15)*100%;
      color: #555;
    }
  }
}
