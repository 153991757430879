.c-table-1 {
  //font-size: 14px;

  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 15px 20px;
    border: 1px solid #d9d9d9;
    vertical-align: top;
    @include margin-root;
  }

  th {
    font-weight: 500; // medium
    background: #fafafa;
  }

  caption {
    color: #ac6000;
    margin-bottom: 5px;
    text-align: left;
  }

  @include sp {
    &--scroll {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 14px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      table {
        width: 670px;
      }
    }
  }
}

.c-table-2 {
  //font-size: 14px;

  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 30px 15px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    vertical-align: top;
    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
    @include margin-root;
  }

  &--nolastborder {
    tr:last-child {
      th, td {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &--resp {
    @include sp {
      table, caption, thead, tbody, tfoot, tr, th, td {
        display: block;
      }
      tr {
        padding: 15px 0;
        border-top: 1px solid #d9d9d9;
      }
      th, td {
        border: none;
        padding: 0;
      }
      th {
        margin-bottom: 1em;
      }
    }
  }

  th {
    font-weight: 500; // medium
  }

  caption {
    margin-top: 0;
    margin-bottom: 15px;
    text-align: left;
  }

  .__year {
    position: relative;
    //top: -.18em;
    font-size: 36px;
    font-family: "acumin-pro", sans-serif;
    font-weight: 100; // acumin-pro thin
    line-height: 1;
    white-space: nowrap;
    color: $color-red;
  }

  &--vertical {
    @include sp {
      table, tbody, tr {
        display: block;
      }

      th {
        display: block;
        padding-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
        border-bottom: none;
      }

      td {
        display: block;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        border-top: none;
      }

      tr:not(:last-child) {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .c-table-2-inner {
    th, td {
      padding-top: 0;
      padding-bottom: 0;
      border: none;
    }
  }

  @include sp {
    &--scroll {
      overflow-x: scroll;

      table {
        width: 670px;
      }
    }
  }
}

.c-form-table {
  @include margin-root;
  margin-bottom: 40px;
  font-size: 14px;
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
  }

  table {
    border-collapse: collapse;
  }

  tr {
    @include sp {
      padding: 15px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  th,
  td {
    padding: 15px 15px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    vertical-align: top;
    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
    @include margin-root;

    input { max-width: 100%; }

    @include sp {
      padding: 0;
      border: none;
    }
  }

  th {
    font-weight: 500; // medium
    @include sp {
      margin-bottom: 5px;
    }
  }

  td {
    > label {
      @include sp {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }

  &__actions {
    margin-top: 50px;
    text-align: center;
  }

  @include sp {
    table, thead, tbody, tfoot, caption, tr, th, td {
      display: block;
    }
  }
}

.c-table-3 {
  //font-size: 14px;

  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 5px 18px;
    border: 1px solid #d9d9d9;
    vertical-align: top;
    @include margin-root;
  }

  th {
    font-weight: inherit;
    background: #fcfbef;
  }

  &--center {
    table {
      margin: 0 auto;
    }
  }
}

