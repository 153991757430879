.p-ir-top-section {
  display: flex;
  margin-bottom: 80px;
  padding: 10px;
  background: url(../img/stripe_bg_01.png);
  @include sp {
    flex-direction: column;
  }
  > * {
    @include margin-root;
    padding: 35px 40px;
    background: #fff;
    margin-left: 10px;
    &:first-child { margin-left: 0; }
    @include sp {
      margin-left: 0;
      margin-top: 10px;
      &:first-child { margin-top: 0; }
      padding: 20px;
    }
  }

  &__index {
    flex: 1 1 auto;
    @include sp {
      padding-top: 0;
    }
  }

  &__info {
    flex: 1 1 auto;
    background: #fcfbef;
    text-align: center;
  }

  .c-index-list__items {
    > :before { content: none; }
    .__image { width: auto; }
  }
}


