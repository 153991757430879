@for $i from 0 through 20 {
  .u-w#{$i * 5} { width: $i * 5%; }
}

@include sp {
  @for $i from 0 through 20 {
    .u-w#{$i * 5}sp { width: $i * 5%; }
  }
}

@for $i from 0 through 20 {
  .u-fz#{$i * 5}p { font-size: $i * 5%; }
}

.u-wauto { width: auto; }
@include sp {
  .u-wautosp { width: auto; }
}
