.c-recipe-list {

  &--slider-4,
  &--slider-5,
  &--slider-6 {
    position: relative;

    // mask
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -32px;
      bottom: 0;
      width: 32px;
      background: white;
      z-index: 1;
      @include sp { content: none; }
    }

    @include sp {
      margin-left: -20px;
      margin-right: -20px;
      overflow-x: scroll;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-bottom: 30px;
      vertical-align: top;
    }
  }

  &--slider-4 &__items,
  &--slider-5 &__items,
  &--slider-6 &__items {
    display: block;
    li {
      margin-bottom: 0;
      @include sp { float: left; }
    }
  } 

  &--slider-4 &__items {
    margin-right: -30px;
    li {
      margin-right: 30px;
      @include sp {
        width: 160px;
        margin-left: 20px;
        margin-right: 0;
        &:last-child {
          box-sizing: content-box;
          padding-right: 20px;
        }
      }
    } 
  }

  &--slider-5 &__items {
    margin-right: -20px;
    li {
      margin-right: 20px;
      @include sp {
        width: 160px;
        margin-left: 20px;
        margin-right: 0;
        &:last-child {
          box-sizing: content-box;
          padding-right: 20px;
        }
      }
    } 
  }

  &--slider-6 &__items {
    margin-right: -16px;
    li {
      margin-right: 16px;
      @include sp {
        width: 160px;
        margin-left: 20px;
        margin-right: 0;
        &:last-child {
          box-sizing: content-box;
          padding-right: 20px;
        }
      }
    } 
  }

  &--col-4 &__items {
    margin-left: -30px;
    @include sp { margin-left: -20px; }
  }

  &--col-4 li {
    width: calc((100% - 30px * 4) / 4);
    margin-left: 30px;
    @include sp {
      width: calc((100% - 20px * 2) / 2);
      margin-left: 20px;
    }
  }

  &--col-5 &__items {
    margin-left: -20px;
    @include sp { margin-left: -20px; }
  }

  &--col-5 li {
    width: calc((100% - 20px * 5) / 5);
    margin-left: 20px;
    @include sp {
      width: calc((100% - 20px * 2) / 2);
      margin-left: 20px;
    }
  }

  &--col-6 &__items {
    margin-left: -16px;
    @include sp { margin-left: -20px; }
  }

  &--col-6 li {
    width: calc((100% - 16px * 6) / 6);
    margin-left: 16px;
    @include sp {
      width: calc((100% - 20px * 2) / 2);
      margin-left: 20px;
    }
  }

  a {
    position: relative;
    display: block;
    text-decoration: none;
  }

  .__image {
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  .__text {
    margin-top: 8px;
    font-size: 14px;
    line-height: (22/14);
  }

  .__time {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 8px;
    color: #fff;
    background: #fc9a00;
    font-size: 13px;
    font-weight: 500; // medium
    line-height: 1;
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  .slick-slide {
    * { outline: none; }
  }

  .slick-arrow {
    @include input-reset;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 0;
    margin-top: -16px;
    padding-top: 32px;
    overflow: hidden;
    border-radius: 0;
    outline: none;
    transition: all 200ms ease;
    cursor: pointer;
    z-index: 2;

    &:hover { transform: scale(1.1); }
    &:active { transform: scale(1.05); }

    &:before {
      @include icon($char-arrow-m);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      font-size: 24px;
      color: $color-red;
    }
  }

  .slick-prev {
    left: -38px;
    &:before {
      transform: rotate(180deg) translateY(4px);
    }
  }

  .slick-next {
    right: -38px;
    &:before {
      transform: rotate(0) translateY(4px);
    }
  }

  &--slider-4 &__items .slick-next {
    margin-right: 30px; }

  &--slider-5 &__items .slick-next {
    margin-right: 20px; }

  &--slider-6 &__items .slick-next {
    margin-right: 16px; }
}
