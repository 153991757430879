.c-pager-nav {
  &__items {
    display: flex;
    justify-content: center;
    background: #f2f2f2;

    li {

      a, span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        color: #333;
      }

      a {
        text-decoration: none;

        &:hover {
          color: #fff;
          background: $color-red;
        }
      }

      span {
        &.current_page {
          color: $color-red;
          background: #fff;
          border: 1px solid #f7b2bc;
        }
      }

      &.__current {
        display: none;
        @include sp {
          display: block;
        }

        span {
          width: 110px;
          background: #fff;
          border: 1px solid #ccc;
        }
      }

      &.__page {
        @include sp {
          display: none;
        }
      }

      &.__prev,
      &.__next {
        margin: 0 10px;
      }

      &.__prev {
        i { transform: rotate(180deg); }
      }
    }
  }
}
