.p-recipe-theme-list {
  margin-bottom: 60px;
  @include sp {
    margin-bottom: 0;
  }

  ul {
    display: flex;
    margin-left: -20px;
    @include sp {
      flex-direction: column;
    }
  }

  &__item {
    flex: 1 1 auto;
    margin-left: 20px;
    color: #572513;
    background: #efefef url(../img/recipe_theme_bg.png);
    @include sp {
      position: relative;
      margin-top: 2px;
      margin-left: -20px;
      margin-right: -20px;
      &:first-child {
        margin-top: 0;
        margin-left: -20px;
      }
    }

    a {
      display: block;
      heigth: 100%;
      text-decoration: none;
      @include sp {
        display: flex;
      }
    }
  }

  &__pct {
    @include sp {
      width: 30%;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  &__text {
    flex: 1 1 auto;
    padding: 15px 5px;
    text-align: center;
    line-height: 1.4;
    border-top: 3px solid #999;
    @include sp {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      padding: 5px 15px;
      border-top: none;
      border-left: 3px solid #999;
    }

    .__sub {
      margin-bottom: .25em;
      font-size: 13px;
      font-weight: 200;
    }

    .__main {
      font-size: 15px;
      font-weight: 500;
    }
  }

  // 粉こねレシピ
  &__item--theme-01 {
    background-color: #f7ebd3;
  }
  &__item--theme-01 &__text {
    border-color: #ee9d00;
    .__main span { color: #ee9d00; }
  }

  // 野菜たっぷりレシピ
  &__item--theme-02 {
    background-color: #e3f1da;
  }
  &__item--theme-02 &__text {
    border-color: #92c273;
    .__main span { color: #5ea52f; }
  }

  // アレンジレシピ
  &__item--theme-03 {
    background-color: #fbe3e3;
  }
  &__item--theme-03 &__text {
    border-color: #f22f2f;
    .__main span { color: #f22f2f; }
  }

  // 時短エコレシピ
  &__item--theme-04 {
    background-color: #e1f1fb;
  }
  &__item--theme-04 &__text {
    border-color: #9bc3f4;
    .__main span { color: #60a2e0; }
  }

  // 絶品スイーツレシピ
  &__item--theme-05 {
    background-color: #fce6e8;
  }
  &__item--theme-05 &__text {
    border-color: #ec739e;
    .__main span { color: #ec739e; }
  }

  // 青春コナレシピ
  &__item--theme-06 {
    background-color: #dbefe7;
  }
  &__item--theme-06 &__text {
    border-color: #40b1b5;
    .__main span { color: #40b1b5; }
  }

  // 魚介レシピ
  &__item--theme-07 {
    background-color: #d9e5f4;
  }
  &__item--theme-07 &__text {
    border-color: #4a66b9;
    .__main span { color: #4a66b9; }
  }
}
