.c-news-list {
  margin-top: 20px;
  margin-bottom: 80px;

  &__items {
    margin-bottom: 50px;
    &:last-child { margin-bottom: 0; }
    font-size: 14px;
    line-height: (25/14);

    li {
      &:nth-child(n+2) {
        margin-top: 30px;
        @include sp {
          margin-top: 15px;
        }
      }
      a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
      }

      .__image {
        float: left;
        position: relative;
        top: 2px;
        margin-right: 18px;
        margin-bottom: 30px;
        border: 1px solid #dfdfdf;
        overflow: hidden;
        img {
          display: block;
          margin: -1px;
        }
      }

      .__text {
        //margin-bottom: 30px;
        overflow: hidden;
      }

      a:hover .__title span {
        text-decoration: underline;
      }

      .__date {
        margin-top: 2px;
        font-size: 12px;
        color: #808080;
      }
    }
  }

  &--col-2 &__items {
    column-count: 2;
    column-width: 280px;
    column-gap: 40px;
  }

  &--col-3 &__items {
    column-count: 3;
    column-width: 280px;
    column-gap: 40px;
  }
}

.c-news-list-wrap {
  display: flex;
  margin-bottom: 80px;
  @include sp {
    flex-direction: column;
  }

  &__list {
    margin-right: 40px;
    margin-bottom: 0;
    @include sp {
      margin-right: 0;
      margin-bottom: 60px;
    }
  }

  &__fb {
    .fb-container {
      text-align: center;
      min-width: 380px;
      width: 100%;
      @include sp {
        min-width: 0;
      }
    }
  }
}
