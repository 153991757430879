.c-ctop-index-list {
  margin-top: 56px;
  margin-bottom: 60px;

  @include sp {
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid $color-border;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    a {
      position: relative;
      display: block;
      height: 100%;
      padding: 35px 35px 85px;
      color: inherit;
      text-decoration: none;
      transition: box-shadow 300ms ease;
      &:hover {
        box-shadow: 0 2px 10px rgba(black, .25);
        text-decoration: none;
        @include sp {
          box-shadow: none;
        }
      }

      &:after {
        @include icon($char-arrow-s);
        position: absolute;
        left: 50%;
        bottom: 35px;
        margin-left: -14px;
        display: inline-block;
        font-size: 11px;
        width: 28px;
        height: 28px;
        color: $color-red;
        border-radius: 14px;
        border: 1px solid currentColor;
        text-align: center;
        line-height: 26px;
        text-indent: 2px;
        box-sizing: border-box;
      }
    }

    .__title {
      color: $color-red;
      font-size: 18px;
      letter-spacing: .1em;
      text-align: center;
      line-height: 1.666;
      > span { display: inline-block; }
      @include sp {
        font-size: 20px;
      }
    }

    .__lead {
      margin-top: 20px;
      font-size: 14px;
      line-height: (28/14);
    }
  }

  &__items > * {
    position: relative;
    width: 100%;
    &:before {
      content: none;
      position: absolute;
      top: 36px;
      left: -20px;
      bottom: 36px;
      width: 1px;
      background: $color-border;
    }
    @include sp {
      margin: 0;
      border-top: 1px solid $color-border;
      &:before { content: none; }
    }
  }

  &--col-2 &__items > * {
    margin-left: 40px;
    width: calc((100% - 40px * 1) / 2);
    &:before { content: ''; }
    &:nth-child(n+3) { margin-top: 30px; }
    &:nth-child(2n+1) {
      margin-left: 0;
      &:before { content: none; }
    }
    @include sp {
      margin: 0;
      width: 100%;
      &:nth-child(n+3) { margin-top: 0; }
    }
  }

  &--col-3 &__items > * {
    margin-left: 40px;
    width: calc((99.9% - 40px * 2) / 3);
    &:before { content: ''; }
    &:nth-child(n+4) { margin-top: 30px; }
    &:nth-child(3n+1) {
      margin-left: 0;
      &:before { content: none; }
    }
    @include sp {
      margin: 0;
      width: 100%;
      &:nth-child(n+4) { margin-top: 0; }
    }
  }

  &--col-4 &__items > * {
    margin-left: 40px;
    width: calc((100% - 40px * 3) / 4);
    &:before { content: ''; }
    &:nth-child(n+5) { margin-top: 30px; }
    &:nth-child(4n+1) {
      margin-left: 0;
      &:before { content: none; }
    }
    @include sp {
      margin: 0;
      width: 100%;
      &:nth-child(n+5) { margin-top: 0; }
    }
  }

}
