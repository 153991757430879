.c-group-idea {
  padding: 40px;
  color: #fff;
  background: #a18c87;
  text-align: center;
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
    padding: 40px 20px;
  }

  &__pct {
    margin-bottom: 30px;
  }

  &__text {
    margin: auto;
    max-width: 65%;
    text-align: left;
    @include sp {
      max-width: none;
    }
  }
}
