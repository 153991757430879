.u-alL { text-align: left !important; }
.u-alC { text-align: center !important; }
.u-alR { text-align: right !important; }
@include sp {
  .u-alLsp { text-align: left !important; }
  .u-alCsp { text-align: center !important; }
  .u-alRsp { text-align: right !important; }
}

.u-valT { vertical-align: top !important; }
.u-valM { vertical-align: middle !important; }
.u-valB { vertical-align: bottom !important; }
@include sp {
  .u-valTsp { vertical-align: top !important; }
  .u-valMsp { vertical-align: middle !important; }
  .u-valBsp { vertical-align: bottom !important; }
}
