.c-inpage-nav {
  
  &__items {
    padding: 10px;
    font-size: 14px;
    text-align: center;
    background: #f2f2f2;

    li {
      display: inline-block;
      margin: 3px 15px;

      &:before {
        @include icon($char-arrow-s);
        display: inline-block;
        margin-right: 8px;
        color: $color-red;
        font-size: 12px;
        transform: rotate(90deg);
      }
    }

    a {
      text-decoration: none;
    }
  }

  &--vertical &__items {
    padding: 20px 10px;
    text-align: left;
    li {
      display: block;
    }
  }
}
