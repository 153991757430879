.c-more-list {
  border-top: 1px solid #d9d9d9;
  dt {
    position: relative;
    padding: 7px 40px 7px 20px;
    border: 1px solid #d9d9d9;
    border-top: none;
    cursor: pointer;
    transition: all 400ms ease;

    &.is-open {
      background: #f2f2f2;
    }

    &:after,
    > span:before,
    > span:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
    }

    &:after {
      margin-top: -11px;
      width: 21px;
      height: 21px;
      background: #ccc;
      transform: rotate(270deg);
      transition: all 400ms ease;
    }

    &.is-open:after {
      background: #808080;
      transform: rotate(0deg);
    }

    // +
    > span:before,
    > span:after {
      width: 11px;
      height: 1px;
      margin-top: -1px;
      margin-right: 5px;
      background: #fff;
      transition: all 400ms ease;
      z-index: 1;
    }

    > span:before {
      transform: rotate(90deg);
    }

    &.is-open > span:before {
      transform: rotate(90deg) translateX(-8px);
      opacity: 0;
    }

    > span:after {
      transform: rotate(0deg);
    }
  }

  dd {
    @include margin-root;
    display: none;
    padding: 25px 20px 25px 20px;
    border: 1px solid #d9d9d9;
    border-top: none;
  }
}
