$breakpoint: 768px;
$breakpoint_tb: 900px;

$base-width: 1040px;
$base-width-sp: 90%;
$narrow-width: 800px;

$header-top-height: 59px;
$header-bottom-height: 82px;
$header-top-height-sp: $header-top-height;

$color-black: #222;
$color-beige: #faf7df;
$color-red: #e60020;
$color-border: rgba(black, .1);

$z-index-header: 5000;

$char-arrow-s: '\e900';
$char-arrow-m: '\e901';
$char-double-arrow: '\e902';
$char-newwin: '\e903';
$char-search: '\e904';
$char-search-color: '\e907';
$char-global-color: '\e905';
