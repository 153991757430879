a {
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: $color-red;
    text-decoration: underline;
  }
  &:visited {
  }
}

.c-note {
  display: table;
  width: 100%;
  font-size: 13px;
  line-height: (22/13);
  letter-spacing: .02em;
  color: #555;
  word-break: break-all;
  @include sp {
    font-size: 12px;
    line-height: (22/12);
  }

  > * {
    display: table-cell;
    &:first-child {
      white-space: nowrap;
      width: 1%;
    }
  }
}

.c-notes {
  display: table;
  width: 100%;
  font-size: 13px;
  line-height: (22/13);
  letter-spacing: .02em;
  color: #555;
  > * {
    display: table-row;
    > * {
      display: table-cell;
      &:first-child {
        white-space: nowrap;
      }
    }
  }
}

.c-attention {
  color: #f00;
}

.c-ul {
  padding-left: 1.7em;
  list-style: outside disc;
}

.c-ol {
  padding-left: 1.7em;
  list-style: outside decimal;

  &--space {
    > li:not(:last-child) {
      margin-bottom: .5em;
    }
  }
}

.c-dl {
  dt {
    font-weight: 500; // medium
  }
  dd {
    margin-bottom:: 1.5em;
    @include sp {
      img {
        max-width: 100%;
      }
    }
  }
  > :last-child { margin-bottom: 0; }
}

.c-hr {
  margin-top: 40px;
  margin-bottom: 40px;
  border: none;
  border-top: 1px solid $color-border;
}

