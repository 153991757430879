.c-product-cat-list {
  margin-bottom: 20px;

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;

    li {
      margin-left: 16px;
      margin-bottom: 30px;
      width: calc((99.9% - 16px * 6) / 6);
      @include tb { width: calc((100% - 16px * 4) / 4); }
      @include sp { width: calc((100% - 16px * 2) / 2); }

      .c-grid--col-2 & {
        width: calc((99.9% - 16px * 3) / 3);
        @include tb { width: calc((100% - 16px * 2) / 2); }
        @include sp { width: calc((100% - 16px * 2) / 2); }
      }

      a {
        position: relative;
        display: block;
        height: 100%;
        color: inherit;
        text-decoration: none;
        transition: box-shadow 300ms ease;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
          box-shadow: 0 2px 10px rgba(black, .25);
          transition: opacity 300ms ease;
          opacity: 0;
          z-index: 2;
        }

        &:hover:after {
          opacity: 1;
        }
      }

      .__image {
        padding: 14px 0;
        text-align: center;

        img {
          display: inline-block;
          max-width: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }

      .__text {
        padding: 8px 0;
        font-size: 14px;
        text-align: center;
        line-height: (20/14);
        border-top: 1px solid #f7b2bc;
        > span {
          display: inline-block;
        }
      }

      .__labels {
        padding: 0 0 5px 0;
        text-align: center;
      }
    }
  }

  &--col-5 &__items {
    li {
      width: calc((99.9% - 16px * 5) / 5);
      @include tb { width: calc((100% - 16px * 4) / 4); }
      @include sp { width: calc((100% - 16px * 2) / 2); }
    }
  }
}
