.l-footer {
  box-shadow: 0 -1px 0 rgba(black, .1);

  &__sitemap {
    padding: 55px 20px 45px;
    color: $color-black;
    background: #fff;
    background-size: cover;
    font-size: 13px;
    font-weight: 200; // light
    line-height: 1.5;
    @include sp {
      padding: 0;
    }

    .icon-newwin{
      display: inline-block;
    }
    body.site-product &--company,
    body.site-company &--product {
      display: none;
    }

    li {
      margin-bottom: .85em;
      &:last-child { margin-bottom: 0; }
    }

    a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      @include sp {
        &, &:hover {
          text-decoration: none; }
      }
    }

    &__inner {
      display: flex;
      max-width: $base-width;
      margin: auto;
      @include sp {
        display: block;
      }

      > * {
        flex: 0 1 500px;
        margin-left: 20px;
        &:first-child { margin-left: 0; }
      }
    }

    &__block {
      break-inside: avoid;
      @include sp {
        margin: 0;
      }
    }

    &__title {
      margin-bottom: 1.5em;
      font-size: 14px;
      font-weight: 500; // medium

      @include sp {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 300; // normal
        a {
          display: flex;
          padding: 0 20px;
          height: 55px;
          align-items: center;
          border-bottom: 1px solid $color-border;
        }
      }

      @for $i from 0 through 20 {
        body.gnav-#{$i} &.l-footer__sitemap__title--#{$i} a {
          @include sp {
            color: $color-red;
          }
        }
      }
    }

    &__children {
      @include sp {
        display: none;
      }
    }

    &__helpnav {
      margin-top: 50px;
      &:first-child { margin-top: 0; }
      font-weight: 500; // medium
      @include sp {
        margin-top: 0;
        font-size: 15px;
        font-weight: 300; // normal
        ul {
          display: flex;
          flex-wrap: wrap;
        }
        li {
          margin: 0;
          width: 50%;
          border-bottom: 1px solid $color-border;
          &:nth-child(2n) {
            border-left: 1px solid $color-border;
          }
        }
        a {
          display: flex;
          height: 55px;
          padding: 0 20px;
          align-items: center;
        }
      }
    }

    &__misc {
      margin-top: 50px;
      &:first-child { margin-top: 0; }
      @include sp {
        margin-top: 0;
        padding: 18px 20px;
        font-size: 13px;
        li {
          display: inline-block;
          margin: 0;
          margin-right: 1.5em;
          a {
            display: inline-block;
            padding: .25em 0;
          }
        }
      }
    }
  }

  &__pagetop {
    position: relative;
    max-width: $base-width;
    margin: auto;

    a {
      position: absolute;
      top: -23px;
      right: 20px;
      display: block;
      width: 46px;
      height: 0;
      padding-top: 46px;
      color: #fff;
      background: #e60020;
      border-radius: 50%;
      overflow: hidden;
      transition: all 200ms ease;
      text-decoration: none;
      @include sp {
        top: 50%;
        right: 25px;
        margin-top: -23px;
      }

      &:hover {
        color: #fff;
        transform: scale(1.1);
        box-shadow: 0 2px 10px rgba(black, .25);
        text-decoration: none;
      }

      &:active {
        transform: scale(1.05);
      }

      &:before {
        @include icon($char-arrow-s);
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -7px;
        font-size: 14px;
        transform: rotate(-90deg);
      }
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 25px 0 70px;
    background: #f2f2f2;
    @include sp {
      text-align: center;
      padding-bottom: 10px;
    }
  }

  &__logo {
    span {
      display: block;
      width: 177px;
      height: 0;
      padding-top: 25px;
      background: url(../img/logo.png) no-repeat;
      overflow: hidden;
    }
  }

  &__switchnav {
    display: flex;
    margin-left: 15px;
    line-height: 1.25;
    @include sp {
      font-size: 14px;
      margin-left: 0;
      width: calc(100% - 177px);
      margin-left: 0;
    }

    li {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 1.4em;
        margin-top: -.6em;
        display: block;
        border-left: 1px solid $color-border;
      }
      &:first-child:before {
        content: none;
      }
    }

    a {
      display: inline-block;
      padding: 3px 20px;
      color: inherit;
      text-decoration: none;
      &:hover { color: $color-red; }
    }
  }

  &__copyright {
    margin-left: auto;
    padding-right: 25px;
    font-size: 12px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
    letter-spacing: .08em;
    color: #666;
    @include sp {
      width: 100%;
      text-align: center;
      padding: 25px 40px;
      font-size: 12px;
      line-height: (25/12);
      letter-spacing: .06em;
      > span {
        display: inline-block;
      }
    }
    @include en {
      margin: auto;
      padding: 0;
    }
  }
}
