.__mds {
  position: relative;
  margin: 50px auto 20px;
  padding: 4px 15px;
  color: #fff;
  background: #ccc;
  font-size: 15px;
  font-weight: 500; // medium
  &:before {
    content: '';
    position: absolute;
    top: -50px;
    right: 3px;
    bottom: -20px;
    width: 0;
    border-left: 1px dashed rgba(black, .2);
  }
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
  }

  code {
    display: inline-block;
    margin-left: 1em;
    font-size: 12px;
    letter-spacing: normal;
    font-family: 'Lucida Console', monospace;
    b {
      border-bottom: 2px solid rgba(white, .3);
    }
  }
}

.__dummy-div {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 2px dashed #ccc;
  padding: 120px 40px;
  text-align: center;
  font-size: 20px;
  color: silver;
  &:before {
    content: '';
    position: absolute;
    top: -42px;
    right: 20px;
    bottom: -42px;
    width: 6px;
    border-top: 1px solid cyan;
    border-bottom: 1px solid cyan;
    background: rgba(cyan, .1);
    opacity: .7;
  }
}

