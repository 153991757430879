.p-recipe-search {
  text-align: center;

  > * {
    margin-left: 12px;
    &:first-child {
      margin-left: 0;
    }
  }

  .c-textfield {
    @include sp {
      margin-bottom: 10px;
      box-sizing: border-box;
      width: 80%;
    }
  }
}
