.c-grid {
  @include inline-block-list;
  $gap: 22px;

  .c-grid-col {
    vertical-align: top;
    width: 100%;
  }

  @for $i from 2 through 4 {
    &--col-#{$i} {
      .c-grid-col {
        width: calc((100% - #{$gap * ($i - 1)}) / #{$i});
        margin-left: $gap;
        &:nth-child(#{$i}n+1) { margin-left: 0; }
        &:nth-child(n+#{$i+1}) { margin-top: $gap; }
        @include sp {
          display: block;
          width: 100%;
          margin-left: 0;
          margin-top: $gap;
          &:first-child { margin-top: 0; }
        }
      }
    }
  }
}
