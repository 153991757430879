.p-cm-campaign-list {
  margin-bottom: 60px;

  &__items {
    @include clearfix;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    a {
      color: inherit;
      text-decoration: none;
    }

    > li > a {
      @include clearfix;
    }

    .__image {
      margin: -1px -1px 15px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      @include sp {
        //margin-top: 20px;
      }
    }

    .__text {
      font-size: 14px;
      line-height: (26/14);
      padding: 0 19px;
    }

    .__title {
      position: relative;
      display: block;
      color: $color-black;
      font-size: 16px;
      font-weight: 500; // medium
      line-height: (28/16);
      text-align: center;

      > span {
        display: inline-block;
        > span {
          display: inline-block;
        }
      }
    }

    .__lead {
      margin-top: 15px;
    }

    .__button {
      margin: 25px 0;
      text-align: center;
    }

    .__period {
      margin: 30px -1px -1px;
      padding: 5px 20px;
      font-size: 14px;
      font-weight: 500; // medium
      line-height: (24/14);
      text-align: center;
      color: #fff;
      background: $color-red;
    }
  }

  &__items > * {
    width: 100%;
    border: 1px solid #e6e6e6;
    @include sp {
      margin: 0;
    }
  }

  &--col-1 &__items > * {
    &:nth-child(n+2) { margin-top: 30px; }
    .__image {
      float: left;
      margin-right: 40px;
      width: calc((100% - 40px * 3) / 4);
    }
    .__text { overflow: hidden; }
    .__title {
      display: inline-block;
      font-size: 22px;
      line-height: (34/22);
      letter-spacing: .07em;
      &:after {
        display: inline-block;
        position: static;
        margin-left: 15px;
      }
      @include sp {
        font-size: 18px;
        line-height: (30/18);
        letter-spacing: .1em;
      }
    }
    @include sp {
      margin: 0 0 25px;
      &:nth-child(n+2) { margin-top: 0; }
      .__image {
        float: none;
        width: 100%;
        margin-right: 0;
      }
      .__title {
        display :block;
        &:after {
          position: absolute;
          margin-left: 0;
        }
      }
    }
  }

  &--col-2 &__items > * {
    margin-left: 40px;
    width: calc((100% - 40px * 1) / 2);
    &:nth-child(n+3) { margin-top: 30px; }
    &:nth-child(2n+1) { margin-left: 0; }
    .__image {
      float: left;
      margin-right: 40px;
      width: calc((100% - 40px) / 2);
    }
    .__text { overflow: hidden; }
    @include sp {
      width: 100%;
      margin: 0 0 25px;
      .__image {
        float: none;
        width: 100%;
        margin-right: 0;
      }
      &:nth-child(n+3) { margin-top: 0; }
    }
  }

  &--col-3 &__items > * {
    margin-left: 40px;
    width: calc((99.9% - 40px * 2) / 3);
    &:nth-child(n+4) { margin-top: 30px; }
    &:nth-child(3n+1) { margin-left: 0; }
    .__image + .__text .__title:before { height: 3.5em; }
    @include sp {
      width: 100%;
      margin: 0 0 25px;
      .__image + .__text .__title {
        &:before { height: auto; }
      }
      &:nth-child(n+4) { margin-top: 0; }
    }
  }

  &--col-4 &__items > * {
    margin-left: 40px;
    width: calc((100% - 40px * 3) / 4);
    &:nth-child(n+5) {
      margin-top: 30px;
    }
    &:nth-child(4n+1) { margin-left: 0; }
    .__image + .__text .__title {
      &:before { height: 3.5em; }
    }
    @include sp {
      width: 100%;
      margin: 0 0 25px;
      .__image + .__text .__title {
        &:before { height: auto; }
      }
      &:nth-child(n+5) { margin-top: 0; }
    }
  }
}
