.c-hd-1 {
  margin-top: 80px;
  margin-bottom: 80px;
  color: $color-red;
  text-align: center;
  font-size: 30px;
  font-weight: 200; // light
  letter-spacing: .1em;
  line-height: 1.5;
  @include sp {
    margin: 70px auto;
    font-size: 20px;
    text-align: center;
    line-height: inherit;
  }

  > span {
    display: inline-block;
  }

  > br {
    line-height: 1;
    font-size: 10px;
  }

  & &__sub {
    display: block;
    margin-top: 20px;
    font-size: 24px;
    @include sp {
      margin-top: 15px;
      font-size: 16px;
    }
  }

  & &__sub-2 {
    display: block;
    margin-top: 10px;
    font-size: 15px;
    color: #222;
  }
}

.c-hd-2-nb {
  position: relative;
  margin-top: 70px;
  margin-bottom: 30px;
  color: $color-red;
  font-size: 20px;
  font-weight: 300; // normal
  letter-spacing: .07em;
  line-height: 1.35;
  @include sp {
    font-size: 18px;
    line-height: 1.5;
  }
}

.c-hd-2 {
  @extend .c-hd-2-nb;
  padding-bottom: 22px;
  border-bottom: 1px solid #d9d9d9;
  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 155px;
    border-bottom: 1px solid $color-red;
  }
}

.c-hd-3 {
  position: relative;
  margin-top: 70px;
  margin-bottom: 25px;
  color: $color-red;
  font-size: 18px;
  font-weight: 300; // normal
  letter-spacing: .07em;
  line-height: 1.35;
  @include sp {
    font-size: 16px;
    line-height: 1.5;
  }
}

.c-hd-4 {
  position: relative;
  margin-top: 60px;
  margin-bottom: 25px;
  color: $color-black;
  font-size: 16px;
  font-weight: 500; // medium
  line-height: 1.35;
  letter-spacing: .07em;
  @include sp {
    font-size: 15px;
    line-height: 1.5;
  }
}

.c-hd-5 {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  color: $color-black;
  font-size: 15px;
  font-weight: 500; // medium
  letter-spacing: .07em;
  line-height: 1.35;
  @include sp {
    font-size: 14px;
    line-height: 1.5;
  }
}
