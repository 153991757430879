.c-link--text,
a.c-link--text {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  color: inherit;
  font-size: 15px;
  text-decoration: underline;

  &:hover {
    color: $color-red;
  }

  &:before {
    @include icon($char-arrow-s);
    position: absolute;
    top: 9px;
    left: 0;
    color: $color-red;
    font-size: 11px;
  }
}

.c-link--pdf,
a.c-link--pdf {
  display: inline-block;
  color: inherit;
  font-size: 15px;
  text-decoration: underline;

  &:hover {
    color: $color-red;
  }

  &:before {
    @extend .c-label-pdf;
    content: 'PDF';
    display: inline-block;
    margin-right: 5px;
  }
}
