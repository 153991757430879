.p-safety-outline {
  padding: 10px;
  background: url(../img/stripe_bg_01.png);

  &__item {
    display: flex;
    margin-top: 10px;
    background: #fff;
    @include sp {
      display: block;
    }

    &:first-child { margin-top: 0; }

    .__text {
      padding: 25px 30px;
    }

    .__title {
      margin-bottom: 10px;
      color: $color-red;
      font-size: 20px;
      font-weight: 300; // normal
      letter-spacing: .07em;
    }

    .__detail {
      font-size: 14px;
      line-height: (26/14);
      letter-spacing: .02em;
    }

    .__pct {
      flex: 0 0 350px;
      img {
        display: block;
        @include sp {
          width: 100%;
        }
      }
    }
  }
}
