#pdcaBox {
  padding: 40px;
  background: #f2f2f2;
  @include sp {
    padding: 20px;
  }

  > * {
    margin-bottom: 1.5em;
    &:last-child { margin-bottom: 0; }
  }

  .planBox,
  .doBox,
  .check_actionBox {
    padding: 30px;
    background: #fff;
    @include sp {
      padding: 20px;
    }

    dt {
      margin-bottom: 10px;
      color: $color-red;
      font-size: 16px;
      font-weight: normal;
    }

    dd {
      > * {
        margin-bottom: 1em;
        &:last-child { margin-bottom: 0; }
      }
    }
  }

  .doBox {
    position: relative;
    margin-bottom: 40px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -35px;
      left: 50%;
      margin-left: -45px;
      border-top: 37px solid #fff;
      border-left: 45px solid transparent;
      border-right: 45px solid transparent;
      border-bottom: 0 solid transparent;
    }
  }
}

.stance {
  margin-bottom: 60px;
  > dl {
    > dt {
      @extend .c-hd-5;
      br { display: none; }
    }
  }
  .stanceTxt {
    @extend .c-colbox;
  }
}

.objective {
  dt {
    font-weight: bolder;
  }
  dd {
    > * {
      margin-bottom: 1em;
      &:last-child { margin-bottom: 0; }
    }
  }

  dl {
    margin-left: 1.5em;
  }
}

.bnumreportBox {
  margin-top: 50px;

  > ul {
    display: flex;
    flex-wrap: wrap;
  }

  > ul > li {
    display: flex;
    flex: 0 1 calc((100% - 40px * 2) / 2);
    flex-basis: calc((100% - 40px * 2) / 2); // IE11
    margin-bottom: 1.5em;
    margin-left: 40px;
    &:nth-child(2n+1) { margin-left: 0; }
    @include sp {
      margin-left: 0;
      flex-basis: 100%;
    }

    img {
      flex: 0 0 90px;
    }

    ul {
      margin-left: 10px;
    }
  }
}

.sp10-04 {
  position: relative;
  padding: 20px 250px 20px 20px;
  background: #f5f3e5;
  min-height: 230px;
  @include sp {
    padding: 15px;
  }
  .pct {
    position: absolute;
    right: 20px;
    bottom: 0;
    @include sp {
      position: static;
      margin-bottom: 10px;
    }
    img { display: block; }
  }
  .name {
    color: #5c7d11;
  }
}
