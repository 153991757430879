.p-404-top-section {
  font-size: 14px;
  text-align: center;

  &__title {
    margin-bottom: 2em;
    font-size: 15px;
    font-weight: 500; // medium
  }

  &__body {
    margin-bottom: 1em;
  }

  &__search {
    display: flex;
    justify-content: center;
    margin: 3em 0;
    > * {
      margin-left: 12px;
      &:first-child { margin-left: 0; }
    }
  }

  &__links {
    font-weight: 500; // medium

    ul {
      @include inline-block-list;
    }

    li {
      margin-right: 1.5em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
