.c-button {
  @extend %button-chrome;
  padding-left: 50px;

  &:before {
    @include icon($char-arrow-s);
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    margin: auto;
    height: 11px;
    color: $color-red;
    font-size: 11px;
  }

  &[target="_blank"] {
    padding-right: 40px;
    &:after {
      @include icon($char-newwin);
      position: absolute;
      top: 0;
      right: 13px;
      bottom: 0;
      margin: auto;
      height: 14px;
      color: #b2b2b2;
      font-size: inherit;
    }
  }

  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }

  &:hover {
    @extend %button-chrome-hover;
    text-decoration: none;
    &:after {
      color: inherit;
    }
  }

  &--search {
    color: #fff;
    background: $color-red;
    font-size: 16px;
    letter-spacing: .07em;
    border-color: $color-red;

    &:before {
      @include icon($char-search);
      position: absolute;
      top: 0;
      left: 13px;
      bottom: 0;
      margin: auto;
      height: 24px;
      color: currentColor;
      font-size: 24px;
    }
  }

  &--wide {
    padding-left: 75px;
    padding-right: 65px;
    &[target="_blank"] {
      padding-right: 65px;
    }
  }

  &--large {
    font-size: 16px;
  }
}
