.c-entry-status {
  display: inline-block;
  width: 100%;
  margin-top: .5em;
  padding: 8px 20px;
  text-align: center;
  line-height: 1.4;
  color: $color-red;
  background: #faf7df;

  &--finished {
    color: #fff;
    background: #d9d9d9;
  }

  .__deadline {
    font-weight: 500; // medium
  }

  .__status {
    display: inline-block;
    margin-left: 1em;
    color: $color-red;
  }
}
