.p-csr-message {
  margin-bottom: 80px;
  @include sp {
    margin-bottom: 20px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
