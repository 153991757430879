.c-recommend-sites {
  margin: 40px -20px;
  padding: 0 20px;
  &:last-child { margin-bottom: 0; }

  &, html.layout-narrow & {
    max-width: none;
  }

  &__title {
    max-width: $base-width;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 500; // medium
    letter-spacing: .05em;

    .__more {
      float: right;
      margin-top: .25em;
      font-size: 14px;
      > * { font-size: inherit; }
      @include sp {
        display: none;
      }
    }
  }

  &__body {
    margin: 0 -20px;
    padding: 40px 20px;
    background: #ededed;

    &__inner {
      max-width: $base-width;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
