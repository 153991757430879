.p-group-message {
  margin-bottom: 60px;
  background: #a58f8a;
  @include sp {
    margin-bottom: 20px;
  }

  &, html.layout-narrow & {
    max-width: none;
    margin-left: -20px;
    margin-right: -20px;
  }

  picture {
    @include sp {
      display: block;
      text-align: right;
      font-size: 0;
    }
  }

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    height: auto;
    @include sp {
      display: inline-block;
    }
  }
}
