.p-product-nav {
  display: flex;
  background: #faf7df;
  @include sp {
    flex-direction: column;
    height: auto;
    margin-left: -20px;
    margin-right: -20px;
  }

  &__search {
    flex: 1 1 auto;
    display: flex;
    padding: 40px;
  }

  &__new-products {
    flex: 0 1 auto;
    width: 20%;
    padding: 30px;
    font-size: 16px;
    letter-spacing: .02em;
    border-left: 1px solid $color-border;
    @include sp {
      width: 100%;
      border-left: none;
      border-top: 1px solid $color-border;
    }

    a {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-decoration: none;
      &:hover {
        color: #e60020;
        text-decoration: none;
      }
      @include sp {
        flex-direction: row;
      }
    }
  }
}
