.c-product-list {

  &--slider {
    position: relative;

    // mask
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -24px;
      bottom: 0;
      width: 24px;
      background: white;
      z-index: 1;
      @include sp { content: none; }
    }

    @include sp {
      margin-left: -20px;
      margin-right: -20px;
      overflow-x: scroll;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -22px;
    @include sp {
      margin-right: -20px;
    }

    li {
      width: calc((100% - 22px * 6) / 6);
      margin-right: 22px;
      margin-bottom: 30px;
      vertical-align: top;
      @include tb {
        width: calc((100% - 20px * 4) / 4);
        margin-right: 20px;
      }
      @include sp {
        width: calc((100% - 20px * 2) / 2);
        margin-right: 20px;
      }
    }
  }

  &--slider &__items {
    display: block;

    li {
      margin-bottom: 0;
      @include sp {
        float: left;
        width: 160px;
        &:first-child {
          box-sizing: content-box;
          padding-left: 20px;
        }
        &:last-child {
          box-sizing: content-box;
          padding-right: 20px;
        }
      }
    } 
  }

  a {
    display: block;
    text-decoration: none;
  }

  .__image {
    position: relative;
    padding: 15px;
    //background: rgba(black, .1);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      box-shadow: 0 2px 10px rgba(black, .25);
      transition: opacity 300ms ease;
      opacity: 0;
      z-index: 2;
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  a:hover .__image:after {
    opacity: 1;
  }

  .__text {
    margin-top: 10px;
    font-size: 14px;
    line-height: (22/14);
  }

  .__labels {
    padding: 5px 0;
    text-align: center;

    > * {
      margin-left: .4em;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  //// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  .slick-slide {
    * { outline: none; }
  }

  .slick-arrow {
    @include input-reset;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 0;
    margin-top: -16px;
    padding-top: 32px;
    overflow: hidden;
    border-radius: 0;
    outline: none;
    transition: all 200ms ease;
    cursor: pointer;
    z-index: 2;

    &:hover { transform: scale(1.1); }
    &:active { transform: scale(1.05); }

    &:before {
      @include icon($char-arrow-m);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      font-size: 24px;
      color: $color-red;
    }
  }

  .slick-prev {
    left: -38px;
    &:before {
      transform: rotate(180deg) translateY(4px);
    }
  }

  .slick-next {
    right: -38px;
    &:before {
      transform: rotate(0) translateY(4px);
    }
  }

  &--slider &__items .slick-next {
    margin-right: 16px; }
}
