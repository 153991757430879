.l-topicpath {
  position: relative;
  margin: 0;
  font-size: 12px;
  color: #999;
  z-index: 1;
  @include sp {
    display: none;
  }

  html.layout-narrow & {
    max-width: none;
  }

  ul {
    position: absolute;
    top: 20px;
    left: 0;
    @include inline-block-list;
    line-height: 1;
  }

  li {
    margin-bottom: .5em;

    &:before {
      content: '/';
      display: inline-block;
      margin: 0 .5em;
      color: $color-black;;
    }

    &:first-child {
      &:before { content: none; }
    }

    &:last-child {
      display: inline;
    }
  }

  a {
    color: $color-black;;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
