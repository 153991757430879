.c-site-list-colbox {
  @extend .c-colbox;
  padding-top: 0;
  padding-left: 0;
  @include sp {
    padding: 40px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }

    li {
      flex: 0 1 auto;
      flex-basis: calc((100% - 40px * 2) / 2);
      margin-left: 40px;
      margin-top: 40px;
      @include sp {
        width: 245px;
        margin: 0 auto 40px;
        &:last-child { margin-bottom: 0; }
      }
    }

    .__link {
      float: left;
      margin: 0 20px 10px 0;
      position: relative;
      width: 245px;
      font-size: 14px;
      line-height: (20/14);
      outline: none;
      @include sp {
        float: none;
        margin-bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: box-shadow 200ms ease;
        pointer-events: none;
      }

      &:hover:after {
        box-shadow: 0 2px 10px rgba(black, .25);
      }

      a {
        display: table;
        width: 100%;
        color: inherit;
        background: #fff;
        text-decoration: none;
        outline: none;
      }

      .__image {
        display: table-row;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .__text {
        display: table-cell;
        width: 100%;
        padding: 0 10px;
        text-align: center;
        font-weight: 500; // medium
        vertical-align: middle;
        height: 60px;
        > span { display: inline-block; }
        @include sp {
          padding: 4px 5px;
          font-size: 12px;
          > span { display: inline; }
        }

        i {
          position: absolute;
          right: 4px;
          bottom: 4px;
          font-size: 16px;
        }
      }
    } // link

    .__lead {
      font-size: 13px;
      line-height: (24/13);
      @include sp {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
}
