.p-safety-nav {
  margin: 0 -20px;
  padding: 15px 25px;
  background: #f9d2d1;
  max-width: none;
  html.layout-narrow & {
    max-width: none;
  }

  &__inner {
    @include inline-block-list;
    margin: auto;
    max-width: $base-width;
    text-align: center;

    > * {
      position: relative;
      margin: 12px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -18px;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        border-top: 1px solid #fff;
        border-left: 1px solid transparent;
        border-right: 1px solid #fff;
        border-bottom: 1px solid transparent;
        transform: rotate(45deg);
      }

      &:first-child:before {
        content: none;
      }
    }

    a {
      display: block;
      width: 120px;
      text-align: center;
      font-size: 14px;
      line-height: (30/14);
      letter-spacing: .02em;
      text-decoration: none;
      color: $color-red;
      background: #fff;
      border-radius: 3px;
      transition: all 200ms ease;

      &:hover,
      &.is-current {
        color: #fff;
        background: #e60020;
      }
    }

  }
}
