.c-label-pdf {
  display: inline-block;
  //margin: 0;
  padding: 0 5px 2px;
  color: #fff;
  background: #b3b3b3;
  border-radius: 2px;
  font-size: 15px;
  font-family: "acumin-pro-condensed", sans-serif;
  font-weight: 600; // acumin-pro-condensed semibold
  letter-spacing: .07em;
  line-height: 16px;
}

.c-label-pdfsize {
  font-size: (12/14)*100%;
  color: #808080;
}

.c-label-new {
  display: inline-flex;
  height: 22px;
  justify-content: center;
  align-items: center;
  padding: 0 12px 2px;
  color: #fff;
  background: $color-red;
  border-radius: 2px;
  font-size: 16px;
  letter-spacing: .05em;
  line-height: 20px;
  vertical-align: middle;
}

.c-label-frozen,
.c-label-renewal,
.c-label-func {
  display: inline-flex;
  height: 22px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  color: #fff;
  background: #5fb6ff;
  border-radius: 2px;
  font-size: 13px;
  line-height: 20px;
  vertical-align: middle;
}

.c-label-renewal {
  color: #fff;
  background: #f599a6;
  font-size: 12px;
}

.c-label-func {
  color: #fff;
  background: #15aa46;
  font-size: 13px;
}

%label-handcraft {
  display: inline-flex;
  min-width: 52px;
  height: 19px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #ccc;
  border-radius: 2px;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: .02em;
  vertical-align: middle;
}
.c-label-pan       { @extend %label-handcraft; background-color: #a14a32; }
.c-label-kasi      { @extend %label-handcraft; background-color: #f5731b; }
.c-label-udon      { @extend %label-handcraft; background-color: #b9aa45; }
.c-label-mushimono { @extend %label-handcraft; background-color: #de6767; }
.c-label-mix       { @extend %label-handcraft; background-color: #7ca85b; }
.c-label-etc       { @extend %label-handcraft; background-color: #a14a32; }

.c-label-tel {
  font-size: 38px;
  font-family: 'acumin-pro-condensed';
  //font-weight: 600; // acumin-pro-condensed semibold
  line-height: 1.2;

  .icon-tel {
    position: relative;
    top: -2px;
    font-size: 60%;
    margin-right: 5px;
    color: $color-red;
  }
}

.c-label-recruit {
  margin-top: 1.5em;
  padding: 10px 15px;
  border: 1px solid #d9d9d9;
  color: $color-red;
  text-align: center;
}

.c-label-cookingtime,
.c-label-calorie {
  display: inline-flex;
  justify-content: space-between;
  margin-right: 4px;
  min-width: 163px;
  padding: 10px 10px 9px 10px;
  color: #000;
  background: #fc9a00;
  border-radius: 3px;
  font-weight: bolder;
  line-height: 1;

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  > dt {
    position: relative;
    margin-right: 10px;
    margin-bottom: 1px;
    padding-left: 25px;
    font-size: 12px;
    color: #fff;
    letter-spacing: .05em;

    .icon-clock,
    .icon-calorie {
      position: absolute;
      top: -4px;
      left: 0;
      font-size: 20px;
      margin-right: 8px;
    }
  }

  > dd {
    font-size: 14px;
  }
}

.c-label-required {
  color: $color-red;
}
