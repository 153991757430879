.p-group-functions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  dl {
    width: calc(50% - 40px);
    &:nth-child(n+3) { margin-top: 40px; }
    @include sp {
      width: 100%;
      &:nth-child(n+2) { margin-top: 40px; }
    }
  }
  dt {
    strong {
      display: block;
      margin-bottom: 15px;
      font-family: "acumin-pro", sans-serif;
      font-weight: 100; // acumin-pro thin
      font-size: 48px;
      line-height: 1;
      letter-spacing: -.01em;
    }
    span {
      display: block;
      font-weight: 500; // medium
      letter-spacing: .07em;
    }
  }
  dd {
    margin-top: 20px;
  }
}
