.p-faq-index {
  font-size: 16px;
  line-height: (26/16);
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  li {
    position: relative;
    margin-bottom: 25px;
    padding-left: 32px;
    &:last-child { margin-bottom: 0; }

    &:before {
      content: 'Q';
      display: inline-block;
      position: absolute;
      top: .22em;
      left: 0;
      width: 22px;
      height: 22px;
      line-height: 20px;
      font-family: "acumin-pro-condensed", sans-serif;
      font-weight: 600; // acumin-pro-condensed semibold
      font-size: 18px;
      text-align: center;
      color: #fff;
      background: $color-red;
      border-radius: 50%;
    }
  }
}
