.p-brand-top-section {
  max-width: none;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  &:before,
  &:after {
    content: '';
    display: table;
    width: 100%;
  }

  > * {
    max-width: $base-width;
    margin-left: auto;
    margin-right: auto;
    html.layout-narrow & {
      max-width: $narrow-width;
    }
  }

  &__header {
    margin-bottom: 50px;

    .__title {
      @extend .c-hd-1;
      margin-bottom: 0;
    }

    .__button {
      margin-top: 20px;
      text-align: center;
    }

    .__link {
      margin-top: 15px;
      text-align: center;
    }
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // レシピ
  &--recipe {
    padding-bottom: 80px;
    background: #fff url(../img/top_recipe_bg.jpg) no-repeat;
    background-size: 100% auto;
  }

  // 学ぶ・楽しむ
  &--entertainment {
    padding-bottom: 70px;
    background: #f0e9e7 url(../img/top_entertainment_bg.jpg) no-repeat;
    background-size: 100% auto;
  }

  // お知らせ
  &--news &__header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;

    .__title {
      font-size: 30px;
    }
  }
}
