.l-localnav {
  margin: 100px -20px 0;
  padding: 50px 20px;
  color: #fff;
  background: #666;

  .c-recommend-sites + & {
    margin-top: -40px;
  }

  &--products {
    margin-top: 80px;
    color: #000;
    background: #faf7df;
  }

  &--recipe {
    color: #000;
    background: #faf7df;
  }

  &, html.layout-narrow & {
    max-width: none;
  }

  @include sp {
    padding: 30px 0 60px;
  }

  &:last-child { margin-bottom: 0; }

  > * {
    max-width: $base-width;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 300; // normal
    text-align: center;
    font-size: 18px;
    letter-spacing: .1em;
    a:hover {
      color: inherit;
    }
  }

  &--products &__title {
    color: $color-red;
  }

  &__list {
    font-size: 14px;
    line-height: (22/14);
    letter-spacing: .02em;

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      flex: 0 1 calc((100% - 40px * 3) / 4);
      flex-basis: calc((100% - 40px * 3) / 4); // IE11
      margin-left: 40px;
      border-top: 1px solid rgba(white, .1);
      border-bottom: 1px solid rgba(white, .1);
      &:nth-child(4n+1) { margin-left: 0; }
      &:nth-child(n+5) { border-top: none; }

      span > span {
        display: inline-block;
      }

      @include sp {
        flex: 1 1 100%;
        margin-left: 0;
        &:nth-child(n+2) { border-top: none; }
      }
    }

    a {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 100%;
      min-height: 55px;
      text-decoration: none;
      @include sp {
        padding: 0 20px;
      }

      img {
        flex: 0 0 auto;
        position: relative;
        left: -10px;
        width: 55px;
        height: 55px;
        @include sp {
          left: -20px;
        }
      }

      &:hover,
      &.is-current,
      &.is-current-category {
        color: inherit;
        background-color: rgba(black, .1);
        text-decoration: none;
      }
    }

    i { font-size: 14px; }
  }

  &--products &__list {
    column-gap: 40px;
    column-count: 4;
    @include sp {
      column-count: 1;
    }

    ul {
      display: block;
      break-inside: avoid;
      border-top: 1px solid rgba(black, .1);
      @include sp {
        border-top: none;
        &:first-child {
          border-top: 1px solid rgba(black, .1);
        }
      }
    }

    li {
      margin-left: 0;
      border-top: none;
      border-color: rgba(black, .1);

      span > span {
        display: inline-block;
      }
    }

    a {
      &:hover {
        color: inherit;
        background: rgba(black, .1);
      }
    }
  }

  &__search {
    padding-top: 50px;
    @include sp {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
